import {
    GET_ALL_CHIEF,
    GET_CHIEF_LIST, LOADING_CHIEF_LIST
} from "../types";

const initialState = {
    pagination: [],
    chiefList: [],
    allChief: [],
    loadingChiefList: true,
}

export const chiefReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_CHIEF_LIST:
            return {
                ...state,
                chiefList: action.payload.resources,
                pagination: action.payload.meta.pagination,
                loadingChiefList: false
            }
        case GET_ALL_CHIEF:
            return {
                ...state,
                allChief: action.payload.resources,
                loadingChiefList: false
            }
        case LOADING_CHIEF_LIST:
            return {
                ...state,
                loadingChiefList: true
            }
        default:
            return state
    }
}