import {
    GET_CHIEF_LIST,
    GET_EMPLOYEE_LIST,
    START_ADD_EMPLOYEE,
    STOP_ADD_EMPLOYEE,
    GET_EMPLOYEE,
    GET_EMPLOYEE_ACCOUNTS,
    LOADING_EMPLOYEE_ACCOUNTS,
    LOADING_EMPLOYEE,
    LOADING_EMPLOYEE_LIST, START_UPDATE_EMPLOYEE, STOP_UPDATE_EMPLOYEE, START_UPDATE_ACCOUNTS, STOP_UPDATE_ACCOUNTS
} from "../types";

const initialState = {
    employeeList: [],
    employeeAccountList: [],
    pagination: [],
    chiefList: [],
    employee: {},
    loadingEmployee: true,
    loadingEmployeeList: true,
    addingNewEmployee: false,
    editingEmployee: false,
    editingAccounts: false,
    loadingEmployeeAccounts: false,
}

export const employeeReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_EMPLOYEE_LIST:
            return {
                ...state,
                employeeList: action.payload.resources,
                pagination: action.payload.meta.pagination,
                loadingEmployeeList: false
            }
        case LOADING_EMPLOYEE_LIST:
            return {
                ...state,
                loadingEmployeeList: true
            }
        case GET_EMPLOYEE:
            return {
                ...state,
                employee: action.payload.resources,
                loadingEmployee: false
            }
        case LOADING_EMPLOYEE:
            return {
                ...state,
                loadingEmployee: true
            }
        case GET_CHIEF_LIST:
            return {
                ...state,
                chiefList: action.payload.resources
            }
        case START_ADD_EMPLOYEE:
            return {
                ...state,
                addingNewEmployee: true,
            }
        case STOP_ADD_EMPLOYEE:
            return {
                ...state,
                addingNewEmployee: false,
            }
        case GET_EMPLOYEE_ACCOUNTS:
            return {
                ...state,
                employeeAccountList: action.payload.resources,
                loadingEmployeeAccounts: false
            }
        case LOADING_EMPLOYEE_ACCOUNTS:
            return {
                ...state,
                loadingEmployeeAccounts: true
            }
        case START_UPDATE_EMPLOYEE:
            return {
                ...state,
                editingEmployee: true
            }
        case STOP_UPDATE_EMPLOYEE:
            return {
                ...state,
                editingEmployee: false
            }
        case START_UPDATE_ACCOUNTS:
            return {
                ...state,
                editingAccounts: true
            }
        case STOP_UPDATE_ACCOUNTS:
            return {
                ...state,
                editingAccounts: false
            }
        default:
            return state
    }
}