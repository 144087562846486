import React from "react";
import {connect} from 'react-redux'
import {fetchEmployeeList} from "../../redux/actions/employeeActions";
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card,
    Typography, CardHeader, CardContent, Chip
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import CardActions from "@material-ui/core/CardActions";
import Alert from '@material-ui/lab/Alert';
import {
    Close,
    FilterList, GetApp,
    Mail,
    PersonAddDisabledOutlined,
    PersonAddOutlined
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DebouncedInputComponent from "../../components/DebounceInput";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import {fetchAllRegions} from "../../redux/actions/regionActions";
import {fetchAllOrganisations} from "../../redux/actions/organisationActions";
import {fetchAllJobPositions} from "../../redux/actions/jobPositionActions";
import {fetchAllDepartments} from "../../redux/actions/departmentActions";
import Tooltip from "@material-ui/core/Tooltip";
import {API_URL} from "../../config";
import Dialog from "@material-ui/core/Dialog";
import {fetchAllChief} from "../../redux/actions/cheifActions";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import AuthService from "../../services/AuthService";
import {showAlert} from "../../redux/actions/alertActions";


class EmployeeList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            param: '',
            params: [],
            typeParam: 'fullName',
            showFilter: false,
            regionCode: '',
            departmentCode: '',
            jobPositionCode: '',
            organisationCode: '',
            statusCode: 'active',
            chiefId: '',
            employeeId: '',
            message: '',
            cFio: true,
            cEmail: true,
            cPhone: true,
            cInternalPhone: true,
            cChief: true,
            cRegion: true,
            cOrganisation: true,
            cDepartment: true,
            cJobPosition: true,
            cStatus: true,
            selectColModal: false
        }
    }
    componentDidMount() {
        this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, null, null, true).catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllDepartments().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllRegions().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllOrganisations().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllJobPositions().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllChief().catch(e => this.props.showAlert(e.message, true))
    }
    closeSelectColModal = () => {
        this.setState({
            ...this.state,
            selectColModal: false
        })
    }
    openSelectColModal = () => {
        this.setState({
            ...this.state,
            selectColModal: true
        })
    }
    setCFio = () => {
        this.setState({
            ...this.state,
            cFio: true
        })
    }
    setCEmail = () => {
        this.setState({
            ...this.state,
            cEmail: true
        })
    }
    setCPhone = () => {
        this.setState({
            ...this.state,
            cPhone: true
        })
    }
    setCInternalPhone = () => {
        this.setState({
            ...this.state,
            cInternalPhone: true
        })
    }
    setCChief = () => {
        this.setState({
            ...this.state,
            cChief: true
        })
    }
    setCRegion = () => {
        this.setState({
            ...this.state,
            cRegion: true
        })
    }
    setCOrganisation = () => {
        this.setState({
            ...this.state,
            cOrganisation: true
        })
    }
    setCDepartment = () => {
        this.setState({
            ...this.state,
            cDepartment: true
        })
    }
    setCJobPosition = () => {
        this.setState({
            ...this.state,
            cJobPosition: true
        })
    }
    setCStatus = () => {
        this.setState({
            ...this.state,
            cStatus: true
        })
    }
    saveToExcel = async () => {
        try{
            let uri = API_URL + '/import_employee?';
            if(this.state.params){
                this.state.params.forEach((item) => {
                    if(item.param){
                        uri += `${item.typeParam}=${item.param}&`;
                    }
                })
            }
            if(this.state.cFio){
                uri += 'cFio=true&'
            }
            if(this.state.cEmail){
                uri += 'cEmail=true&'
            }
            if(this.state.cPhone){
                uri += 'cPhone=true&'
            }
            if(this.state.cInternalPhone){
                uri += 'cInternalPhone=true&'
            }
            if(this.state.cChief){
                uri += 'cChief=true&'
            }
            if(this.state.cRegion){
                uri += 'cRegion=true&'
            }
            if(this.state.cOrganisation){
                uri += 'cOrganisation=true&'
            }
            if(this.state.cDepartment){
                uri += 'cDepartment=true&'
            }
            if(this.state.cJobPosition){
                uri += 'cJobPosition=true&'
            }
            if(this.state.cStatus){
                uri += 'cStatus=true&'
            }

            const response = await fetch(uri, {
                credentials: "include",
                mode: "cors",
                method: 'get'})
            const result = await response.blob()
            let url = window.URL.createObjectURL(result);
            let a = document.createElement('a');
            a.href = url;
            a.download = "uploads.xlsx";
            document.body.appendChild(a);
            a.click();
            a.remove();

        }catch (e){
            this.props.showAlert(e.message)
        }
    }
    removeEmployee = async (id) => {
        try{
            const response = await fetch(API_URL + `/employees/${id}/change_status`, {
                credentials: "include",
                method: 'put',
                mode: "cors"
            })
            if(!response.ok){
                throw new Error('не удалось изменить статус сотрудника')
            }
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, this.state.params).then(() => {
                this.props.showAlert('Статус сотрудника изменен')
            }).catch(e => {
                throw new Error(e.message)
            })
        }catch (e){
            this.props.showAlert(e.message, true)
        }
    }
    startSender = (value) => {
        this.setState({
            ...this.state,
            messageDialog: true,
            employeeId: value
        })
    }
    stopSender = () => {
        this.setState({
            ...this.state,
            messageDialog: false,
            employeeId: ''
        })
    }
    sendMessage = async () => {
        try{
            const response = await fetch(API_URL + `/employees/${this.state.employeeId}/send_message`, {
                credentials: "include",
                method: 'post',
                mode: "cors",
                headers: {
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message: this.state.message
                })
            })
            if(!response.ok){
                throw new Error('не удалось отправить сообщение')
            }
            this.setState({
                ...this.state,
                messageDialog: false
            })
            this.props.showAlert("Сообщение отправлено")
        }catch (e){
            this.props.showAlert(e.message, true)
        }
    }
    onSetMessage = (event) => {
        this.setState({
            ...this.state,
            message: event.target.value
        })
    }
    onConfirmFilter = () => {
        let params = [
            {
              typeParam: 'region',
              param: this.state.regionCode
            },
            {
                typeParam: 'chief',
                param: this.state.chiefId
            },
            {
                typeParam: 'organisation',
                param: this.state.organisationCode
            },
            {
                typeParam: 'jobPosition',
                param: this.state.jobPositionCode
            },
            {
                typeParam: 'department',
                param: this.state.departmentCode
            },
            {
                typeParam: 'status',
                param: this.state.statusCode
            }
        ]

        this.setState({
            ...this.state,
            page: 0,
            showFilter: false,
            params: params
        }, () => {
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, this.state.params)
        })

    }
    onDropFilter = () => {
        this.setState({
            regionCode: '',
            departmentCode: '',
            jobPositionCode: '',
            organisationCode: '',
            chiefId: '',
            showFilter: false
        },() => {
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage,null, null,true)
        })
    }
    onShowFilter = () => {
        this.setState({
            ...this.state,
            showFilter: true
        })
    }
    onCloseFilter = () => {
        this.setState({
            ...this.state,
            showFilter: false
        })
    }
    onSetRegionCode = (event) =>{
        this.setState({
            ...this.state,
            regionCode: event.target.value
        })
    }
    onSetJobPositionCode = (event) =>{
        this.setState({
            ...this.state,
            jobPositionCode: event.target.value
        })
    }
    onSetStatusCode = (event) => {
        this.setState({
            ...this.state,
            statusCode: event.target.value
        })
    }
    onSetDepartmentCode = (event) =>{
        this.setState({
            ...this.state,
            departmentCode: event.target.value
        })
    }
    onSetOrganisationCode = (event) =>{
        this.setState({
            ...this.state,
            organisationCode: event.target.value
        })
    }
    onSetChief = (event) => {
        this.setState({
            ...this.state,
            chiefId: event.target.value
        })
    }
    handleChangePage = (event, newPage) => {
        this.setState({
            ...this.state,
            page: newPage
        }, () => {
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, this.state.params)
        })
    }
    handleChangeRowsPerPage = (event) => {
        this.setState({
            ...this.state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, () => {
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, this.state.params)
        })
    }
    onSetParam = (value) => {
        this.setState({
            ...this.state,
            param: value
        },() => {
            this.props.fetchEmployeeList(this.state.page+1, this.state.rowsPerPage, this.state.params, {
                typeParam: this.state.typeParam,
                param: this.state.param
            })
        })
    }
    onSetTypeParam = (event) => {
        this.setState({
            ...this.state,
            typeParam: event.target.value
        })
    }
    onOpenEmployee = (id) => {
        this.props.history.push("/employees/"+id);
    }
    onOpenAddEmployee = () => {
        this.props.history.push("/employees/add");
    }

    render() {
        const isLoading = this.props.loadingEmployeeList

        if (isLoading) {
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (!isLoading &&
            <Grid container direction="column" component={Card}>
                <CardActions disableSpacing>
                    <Grid container direction="row" justify="space-between">
                        <Grid item>
                            <Grid container direction="row" alignItems="flex-end" spacing={2}>
                                <Grid item>
                                    <DebouncedInputComponent text={this.state.param} updateText={this.onSetParam} />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        className="select-field"
                                        id="outlined-select-currency-native"
                                        select
                                        label="поле поиска"
                                        value={this.state.typeParam}
                                        onChange={this.onSetTypeParam}
                                        variant="standard"
                                    >
                                        <option value='id'>ID</option>
                                        <option value='fullName'>Ф.И.О.</option>
                                        <option value='email'>Email</option>
                                        <option value='phone'>Телефон</option>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Tooltip title="окно фильтров">
                                <Button variant="contained" aria-label="add to favorites" onClick={this.onShowFilter}>
                                    <FilterList />
                                </Button>
                            </Tooltip>
                            {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                            <Tooltip title="добавить нового сотрудника">
                                <Button variant="contained" aria-label="share" onClick={this.onOpenAddEmployee}>
                                    <PersonAddOutlined />
                                </Button>
                            </Tooltip>}
                            <Tooltip title="сохранить в excel">
                                <Button variant="contained" aria-label="share" onClick={this.openSelectColModal}>
                                    <GetApp />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardActions>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Ф.И.О.</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Телефон</TableCell>
                                <TableCell>Должность</TableCell>
                                <TableCell>Отдел</TableCell>
                                <TableCell>Организация</TableCell>
                                <TableCell>Регион</TableCell>
                                <TableCell>Статус</TableCell>
                                {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                <TableCell>Действие</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.employeeList.map((item, index) => (
                                <TableRow hover key={index} className="tableRow">
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.id}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.fullName}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.email}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.phone}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.jobPosition}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.department}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.organisation}</TableCell>
                                    <TableCell onClick={() => this.onOpenEmployee(item.id)}>{item.region}</TableCell>
                                    <TableCell>
                                        {item.status === 'active' ?
                                            <Chip size="small" color="primary" label="работает" />
                                            :
                                            <Chip size="small" color="secondary" label="неработает" />
                                        }

                                    </TableCell>
                                    {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                    <TableCell>
                                        <Tooltip title="Отправить сообщение сотруднику">
                                            <IconButton onClick={() => this.startSender(item.id)}>
                                                <Mail />
                                            </IconButton>
                                        </Tooltip>
                                        {item.status === 'active' ?
                                            <Tooltip title="Уволить сотрудника">
                                                <IconButton onClick={() => this.removeEmployee(item.id)}>
                                                    <PersonAddDisabledOutlined/>
                                                </IconButton>
                                            </Tooltip>
                                            :
                                            <Tooltip title="Вернуть сотрудника">
                                                <IconButton onClick={() => this.removeEmployee(item.id)}>
                                                    <PersonAddOutlined/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                    </TableCell>}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.props.pagination.total}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Dialog open={this.state.messageDialog}
                        onClose={this.stopSender}>
                    <Card variant="outlined">
                        <CardHeader
                            action={
                                <IconButton onClick={this.stopSender}>
                                    <Close />
                                </IconButton>
                            }
                            title="Сообщение для сотрудника"
                        />
                        <CardContent>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    rowsMax={10}
                                    variant="outlined"
                                    label="Сообщение"
                                    value={this.state.message}
                                    onChange={this.onSetMessage} />
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Grid container justify="center">
                                <Button variant="contained" color="primary" onClick={this.sendMessage}>
                                    <Typography>Сохранить</Typography>
                                </Button>
                            </Grid>
                        </CardActions>
                    </Card>
                </Dialog>
                <Dialog
                    open={this.state.showFilter}
                    onClose={this.onCloseFilter}
                >
                        <Card variant="outlined">
                            <CardHeader
                                action={
                                    <IconButton onClick={this.onCloseFilter}>
                                        <Close />
                                    </IconButton>
                                }
                                title="Фильтры для списка сотрудников"
                            />
                            <CardContent>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Регионы"
                                        value={this.state.regionCode}
                                        onChange={this.onSetRegionCode}
                                    >
                                        {this.props.allRegions.map((option) => (
                                            <option key={option.id} value={option.strCode} className="option">
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Департамент"
                                        value={this.state.departmentCode}
                                        onChange={this.onSetDepartmentCode}
                                    >
                                        {this.props.allDepartments.map((option) => (
                                            <option key={option.id} value={option.strCode} className="option">
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Должности"
                                        value={this.state.jobPositionCode}
                                        onChange={this.onSetJobPositionCode}
                                    >
                                        {this.props.allJobPositions.map((option) => (
                                            <option key={option.id} value={option.strCode} className="option">
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Организации"
                                        value={this.state.organisationCode}
                                        onChange={this.onSetOrganisationCode}
                                    >
                                        {this.props.allOrganisations.map((option) => (
                                            <option key={option.id} value={option.strCode} className="option">
                                                {option.name}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Руководитель сотрудника"
                                        value={this.state.chiefId}
                                        onChange={this.onSetChief}
                                    >
                                        {this.props.allChief.map((option) => (
                                            <option key={option.id} value={option.id} className="option">
                                                {option.fullName}
                                            </option>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        select
                                        label="Статус сотрудника"
                                        value={this.state.statusCode}
                                        onChange={this.onSetStatusCode}
                                    >
                                            <option value="active" className="option">
                                                Работает
                                            </option>
                                            <option value="block" className="option">
                                                Уволен
                                            </option>
                                    </TextField>
                                </Grid>
                            </CardContent>
                            <CardActions>
                                <Grid container direction="row" justify="center" spacing={1}>
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={this.onConfirmFilter}>
                                            <Typography>Применить</Typography>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary" onClick={this.onDropFilter}>
                                            <Typography>Сбросить</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardActions>
                        </Card>
                </Dialog>
                <Dialog open={this.state.selectColModal} onClose={this.closeSelectColModal}>
                    <Card variant="outlined">
                        <CardHeader
                            action={
                                <IconButton onClick={this.closeSelectColModal}>
                                    <Close />
                                </IconButton>
                            }
                            title="Поля выгрузки"
                        />
                        <CardContent>
                            <Grid container spacing={2} direction="column" alignItems="center">
                                <Grid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormGroup>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cFio} onChange={this.setCFio} name="fio" />}
                                                label="Ф.И.О."
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cEmail} onChange={this.setCEmail} name="email" />}
                                                label="Email"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cPhone} onChange={this.setCPhone} name="phone" />}
                                                label="Телефон"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cInternalPhone} onChange={this.setCInternalPhone} name="internalPhone" />}
                                                label="Внутр.телефон"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cChief} onChange={this.setCChief} name="chief" />}
                                                label="Руководитель"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cRegion} onChange={this.setCRegion} name="region" />}
                                                label="Регион"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cOrganisation} onChange={this.setCOrganisation} name="organisation" />}
                                                label="Организация"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cDepartment} onChange={this.setCDepartment} name="department" />}
                                                label="Отдел"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cJobPosition} onChange={this.setCJobPosition} name="jobPosition" />}
                                                label="Должность"
                                            />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.state.cStatus} onChange={this.setCStatus} name="status" />}
                                                label="Статус"
                                            />
                                        </FormGroup>
                                        <FormHelperText>Выберите нужные</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button variant="outlined" color="primary" onClick={this.saveToExcel}>
                                        <Typography>Выгрузить</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Dialog>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        employeeList: state.employee.employeeList,
        pagination: state.employee.pagination,
        loadingEmployeeList: state.employee.loadingEmployeeList,
        allDepartments: state.department.allDepartments,
        allRegions: state.region.allRegions,
        allOrganisations: state.organisation.allOrganisations,
        allJobPositions: state.jobPosition.allJobPositions,
        allChief: state.chief.allChief,
    };
};

const mapDispatchToProps = {
    fetchEmployeeList,
    fetchAllRegions,
    fetchAllDepartments,
    fetchAllOrganisations,
    fetchAllChief,
    fetchAllJobPositions,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeList);