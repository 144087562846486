import {
    GET_ALL_JOB_POSITIONS,
    GET_JOB_POSITION_LIST,
    LOADING_JOB_POSITION_LIST, START_ADD_JOB_POSITION, START_REMOVE_JOB_POSITION,
    START_UPDATE_JOB_POSITION, STOP_ADD_JOB_POSITION, STOP_REMOVE_JOB_POSITION, STOP_UPDATE_JOB_POSITION
} from "../types";

const initialState = {
    jobPositionList: [],
    pagination: [],
    allJobPositions: [],
    loadingJobPositionList: true,
    editingJobPosition: false,
    addingJobPosition: false,
    removingJobPosition: false
}

export const jobPositionReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_JOB_POSITION_LIST:
            return {
                ...state,
                jobPositionList: action.payload.resources,
                pagination: action.payload.meta ? action.payload.meta.pagination : [],
                loadingJobPositionList: false
            }
        case GET_ALL_JOB_POSITIONS:
            return {
                ...state,
                allJobPositions: action.payload.resources,
                loadingJobPositionList: false
            }
        case LOADING_JOB_POSITION_LIST:
            return {
                ...state,
                loadingJobPositionList: true
            }
        case START_UPDATE_JOB_POSITION:
            return {
                ...state,
                editingJobPosition: true
            }
        case STOP_UPDATE_JOB_POSITION:
            return {
                ...state,
                editingJobPosition: false
            }
        case START_ADD_JOB_POSITION:
            return {
                ...state,
                addingJobPosition: true
            }
        case STOP_ADD_JOB_POSITION:
            return {
                ...state,
                addingJobPosition: false
            }
        case START_REMOVE_JOB_POSITION:
            return {
                ...state,
                removingJobPosition: true
            }
        case STOP_REMOVE_JOB_POSITION:
            return {
                ...state,
                removingJobPosition: false
            }
        default:
            return state
    }
}