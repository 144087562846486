import {
    GET_ALL_ORGANISATIONS,
    GET_ORGANISATION_LIST,
    LOADING_ORGANISATION_LIST,
    START_ADD_ORGANISATION,
    START_REMOVE_ORGANISATION,
    START_UPDATE_ORGANISATION,
    STOP_ADD_ORGANISATION, STOP_REMOVE_ORGANISATION,
    STOP_UPDATE_ORGANISATION
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchOrganisationList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_ORGANISATION_LIST
            })

            let uri = `/organisations?`;

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(param){
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ORGANISATION_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchAllOrganisations = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_ORGANISATION_LIST
            })

            const response = await httpWrapper('/organisations', {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_ORGANISATIONS,
                payload: result
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const addOrganisation = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_ORGANISATION
            })
            const response = await httpWrapper('/organisations', {
                method: 'post',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_ADD_ORGANISATION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateOrganisation = (data, strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_ORGANISATION
            })
            const response = await httpWrapper(`/organisations/${strCode}`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_ORGANISATION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const removeOrganisation = (strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_REMOVE_ORGANISATION
            })
            const response = await httpWrapper(`/organisations/${strCode}`, {
                method: 'delete',
                credentials: "include", mode: "cors"
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_REMOVE_ORGANISATION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}