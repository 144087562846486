import React from 'react'
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {Button, Card, List, Typography, ListItem} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {fetchAllDepartments} from "../../redux/actions/departmentActions";
import {fetchAllRegions} from "../../redux/actions/regionActions";
import {fetchAllOrganisations} from "../../redux/actions/organisationActions";
import {fetchAllJobPositions} from "../../redux/actions/jobPositionActions";
import {fetchAllEmailDomains} from "../../redux/actions/emailDomainActions";
import {slugify} from "../../libs/sluger";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {addEmployee} from "../../redux/actions/employeeActions";
import {fetchAllCorpSystem} from "../../redux/actions/corpSystemActions";
import {Add, Delete} from "@material-ui/icons";
import {InternalPhoneMask, PhoneMask} from "../../components/PhoneMask";
import {fetchAllChief} from "../../redux/actions/cheifActions";
import {showAlert} from "../../redux/actions/alertActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {genPassword} from "../../libs/passGen";


class AddEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: '',
            mainPassword: genPassword(),
            email: '',
            emailDomain: '',
            internalPhone: '',
            phone: '',
            regionCode: '',
            departmentCode: '',
            jobPositionCode: '',
            organisationCode: '',
            comment: '',
            isChief: false,
            chiefId: '',
            corpSystemCode: '',
            login: '',
            password: '',
            accounts: []
        }
    }

    componentDidMount() {
        this.props.fetchAllJobPositions().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllOrganisations().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllDepartments().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllCorpSystem().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllEmailDomains().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllRegions().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchAllChief().catch(e => this.props.showAlert(e.message, true))
    }

    addEmployee = () => {
        const data = {
            fullName: this.state.fullName,
            mainPassword: this.state.mainPassword,
            email: this.state.email + this.state.emailDomain,
            internalPhone: this.state.internalPhone,
            phone: this.state.phone,
            regionCode: this.state.regionCode,
            departmentCode: this.state.departmentCode,
            jobPositionCode: this.state.jobPositionCode,
            organisationCode: this.state.organisationCode,
            comment: this.state.comment,
            isChief: this.state.isChief,
            chiefId: this.state.chiefId,
            accounts: this.state.accounts,
        }

        this.props.addEmployee(data).then(() => {
            window.location.href = '/'
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetEmail = (event) => {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    onSetMainPassword = (event) => {
        this.setState({
            ...this.state,
            mainPassword: event.target.value
        })
    }

    onSetFullName = (event) => {
        let fullName = event.target.value;
        let fioArray = fullName.split(' ');
        let firstName = fioArray[0] ? slugify(fioArray[0].toLowerCase()) : '';
        let middleName = fioArray[1] ? slugify(fioArray[1][0].toLowerCase()) : '';
        let lastName = fioArray[2] ? slugify(fioArray[2][0].toLowerCase()) : '';
        let email = firstName + '.' + middleName + lastName;
        this.setState({
            ...this.state,
            fullName: fullName,
            email: email
        })
    }

    onSetEmailDomain = (event) => {
        this.setState({
            ...this.state,
            emailDomain: event.target.value
        })
    }

    onSetRegionCode = (event) =>{
        this.setState({
            ...this.state,
            regionCode: event.target.value
        })
    }
    onSetJobPositionCode = (event) =>{
        this.setState({
            ...this.state,
            jobPositionCode: event.target.value
        })
    }
    onSetDepartmentCode = (event) =>{
        this.setState({
            ...this.state,
            departmentCode: event.target.value
        })
    }
    onSetOrganisationCode = (event) =>{
        this.setState({
            ...this.state,
            organisationCode: event.target.value
        })
    }

    onSetComment = (event) => {
        this.setState({
            ...this.state,
            comment: event.target.value
        })
    }

    onSetPhone = (event) => {
        this.setState({
            ...this.state,
            phone: event.target.value
        })
    }

    onSetInternalPhone = (event) => {
        this.setState({
            ...this.state,
            internalPhone: event.target.value
        })
    }

    onSetIsChief = () => {
        this.setState({
            ...this.state,
            isChief: !this.state.isChief
        })
    }

    onSetChief = (event) => {
        this.setState({
            ...this.state,
            chiefId: event.target.value
        })
    }

    onSetCorpSystemCode = (event) => {
        this.setState({
            ...this.state,
            corpSystemCode: event.target.value
        })
    }

    onSetLogin = (event) => {
        this.setState({
            ...this.state,
            login: event.target.value
        })
    }

    onSetPassword = (event) => {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }

    onAddAccount = () => {
        this.setState({
            ...this.state,
            accounts: this.state.accounts.push({
                corpSystemCode: this.state.corpSystemCode,
                login: this.state.login,
                password: this.state.password,
            })
        })
        this.setState({
            ...this.state,
            corpSystemCode: '',
            login: '',
            password: '',
        })
    }

    onRemoveAccount = (index) => {
        let accounts = this.state.accounts;
        accounts.splice(index, 1)
        this.setState({
            accounts: accounts
        })
    }

    render() {
        if(this.props.addingNewEmployee){
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary"/>
                </Grid>
            </Grid>)
        }

        return (
            <Card className="padding-10-20">
                <Typography variant="h4" gutterBottom>
                    Добавление нового сотрудника
                </Typography>
                <Typography variant="h5" gutterBottom>
                    Основная информация
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="Ф.И.О"
                            fullWidth
                            value={this.state.fullName}
                            onChange={this.onSetFullName}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            label="Пароль"
                            fullWidth
                            value={this.state.mainPassword}
                            onChange={this.onSetMainPassword}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="email"
                            fullWidth
                            value={this.state.email}
                            onChange={this.onSetEmail}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            select
                            label="Домены"
                            value={this.state.emailDomain}
                            onChange={this.onSetEmailDomain}
                        >
                            {this.props.allEmailDomains.map((option) => (
                                <option key={option.id} value={option.name} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="formatted-text-mask-input-phone">Личный телефон</InputLabel>
                            <Input
                                value={this.state.phone}
                                onChange={this.onSetPhone}
                                name="phone"
                                id="formatted-text-mask-input-phone"
                                inputComponent={PhoneMask}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="formatted-text-mask-input-internal-phone">Внутренний телефон</InputLabel>
                            <Input
                                value={this.state.internalPhone}
                                onChange={this.onSetInternalPhone}
                                name="internalPhone"
                                id="formatted-text-mask-input-internal-phone"
                                inputComponent={InternalPhoneMask}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            label="Регионы"
                            value={this.state.regionCode}
                            onChange={this.onSetRegionCode}
                        >
                            {this.props.allRegions.map((option) => (
                                <option key={option.id} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            label="Департамент"
                            value={this.state.departmentCode}
                            onChange={this.onSetDepartmentCode}
                        >
                            {this.props.allDepartments.map((option) => (
                                <option key={option.id} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            label="Должности"
                            value={this.state.jobPositionCode}
                            onChange={this.onSetJobPositionCode}
                        >
                            {this.props.allJobPositions.map((option) => (
                                <option key={option.id} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            label="Организации"
                            value={this.state.organisationCode}
                            onChange={this.onSetOrganisationCode}
                        >
                            {this.props.allOrganisations.map((option) => (
                                <option key={option.id} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            select
                            label="Руководитель сотрудника"
                            value={this.state.chiefId}
                            onChange={this.onSetChief}
                        >
                            {this.props.allChief.map((option) => (
                                <option key={option.id} value={option.id} className="option">
                                    {option.fullName}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.state.isChief}
                                    onChange={this.onSetIsChief}
                                    name="isChief"
                                    color="primary"
                                />
                            }
                            label="Сотрудник является руководителем"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Комментарий"
                            multiline
                            rowsMax={4}
                            value={this.state.comment}
                            onChange={this.onSetComment}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Учетные записи сотрудника
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            fullWidth
                            select
                            label="Система"
                            value={this.state.corpSystemCode}
                            onChange={this.onSetCorpSystemCode}
                        >
                            {this.props.allCorpSystems.map((option) => (
                                <option key={option.id} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            label="Логин"
                            fullWidth
                            value={this.state.login}
                            onChange={this.onSetLogin}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            required
                            label="Пароль"
                            fullWidth
                            value={this.state.password}
                            onChange={this.onSetPassword}
                        />
                    </Grid>
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={this.onAddAccount}>
                            <Add/>
                        </Button>
                    </Grid>
                    {this.state.accounts.length > 0 &&
                    <Grid item xs={12}>
                        <List>
                            {this.state.accounts.map((item, index) => (
                                <ListItem key={index} divider>
                                    <Grid container direction="row" alignItems="flex-end" justify="space-between" spacing={2}>
                                        <Grid item xs={6} container direction="row" justify="space-between" spacing={2}>
                                            <Grid item xs={6}>
                                                <Typography color="secondary">
                                                    {item.corpSystemCode}
                                                </Typography>
                                            </Grid>
                                            <Grid item  xs={6}>
                                                <Typography color="primary">
                                                    {item.login}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Button color="primary" variant="outlined" onClick={() => this.onRemoveAccount(index)}>
                                                <Delete />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>}
                    <Grid item xs={12} container justify="center" alignItems="center">
                        <Grid item>
                            <Button color="primary" variant="contained" onClick={this.addEmployee}>
                                <Typography>Добавить сотрудника</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        addingNewEmployee: state.employee.addingNewEmployee,
        allDepartments: state.department.allDepartments,
        allRegions: state.region.allRegions,
        allOrganisations: state.organisation.allOrganisations,
        allJobPositions: state.jobPosition.allJobPositions,
        allEmailDomains: state.emailDomain.allEmailDomains,
        allChief: state.chief.allChief,
        allCorpSystems: state.corpSystem.allCorpSystems,
    };
};

const mapDispatchToProps = {
    fetchAllRegions,
    fetchAllDepartments,
    fetchAllOrganisations,
    fetchAllChief,
    fetchAllEmailDomains,
    fetchAllJobPositions,
    fetchAllCorpSystem,
    addEmployee,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEmployee);