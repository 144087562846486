import React from 'react'
import {fetchEmployee} from "../../redux/actions/employeeActions";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import {
    Button,
    Card, CardContent, CardHeader,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@material-ui/core";
import {Close, Edit, Mail, RemoveRedEye, Visibility, VisibilityOff} from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import EditEmployee from "./EditEmployee";
import EditAccounts from "./EditAccounts";
import Dialog from "@material-ui/core/Dialog";
import Tooltip from "@material-ui/core/Tooltip";
import AuthService from "../../services/AuthService";
import {httpWrapper} from "../../libs/http";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {showAlert} from "../../redux/actions/alertActions";


class Employee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            editInfo: false,
            editAccounts: false,
            showPassword: false,
            password: '',
            newPassword: '',
            sending: false,
            showNewPasswordModal: false,
            accountId: '',
            showNewPassword: false
        }
    }

    componentDidMount() {
        this.props.fetchEmployee(this.props.match.params.id).catch(e => this.props.showAlert(e.message, true))
    }

    sendNewPassword = async (id) => {
        try{
            let uri = '';
            if(this.state.accountId){
                uri = `/accounts/${this.state.accountId}/send_new_password`
            }else{
                uri = `/employees/${id}/send_new_password`
            }

            const response = await httpWrapper(uri,
                {
                    credentials: "include",
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        password: this.state.newPassword
                    })
                })
            if(!response.ok){
                throw new Error('Не удалось изменить пароль')
            }
            const result = await response.json()
            this.closeNewPasswordWindow()
            this.props.showAlert("Новый пароль успешно отправлен")
        }catch (e){
            this.props.showAlert(e.message, true)
        }
    }

    changeModeWriteNewPassword = () => {
        this.setState({
            ...this.state,
            showNewPassword: !this.state.showNewPassword
        })
    }

    closeNewPasswordWindow = () => {
        this.setState({
            ...this.state,
            newPassword: '',
            sending: false,
            showNewPasswordModal: false,
            newAccountPassword: false,
            newEmployeePassword: false,
            showNewPassword: false
        })
    }

    onOpenNewAccountPassword = (accountId) => {
        this.setState({
            ...this.state,
            showNewPasswordModal: true,
            accountId: accountId
        })
    }

    onOpenNewEmployeePassword = () => {
        this.setState({
            ...this.state,
            showNewPasswordModal: true
        })
    }

    onSetNewPassword = (event) => {
        this.setState({
            ...this.state,
            newPassword: event.target.value
        })
    }

    closePasswordWindow = () => {
        this.setState({
            ...this.state,
            showPassword: false,
            password: ''
        })
    }

    showEmployeePassword = async (employeeId) => {
        const response = await httpWrapper(`/employees/${employeeId}/get_password`, {
            credentials: "include"
        })
        if(!response.ok){
            this.props.showAlert('Не удалось получить пароль', true)
        }
        const result = await response.json()

        this.setState({
            showPassword: true,
            password: result.result
        })
    }

    showAccountPassword = async (accountId) => {
        const response = await httpWrapper(`/accounts/${accountId}/get_password`, {
            credentials: "include"
        })
        if(!response.ok){
            this.props.showAlert('Не удалось получить пароль', true)
        }
        const result = await response.json()

        this.setState({
            showPassword: true,
            password: result.result
        })
    }

    onStartEditInfo = () => {
        this.setState({
            ...this.state,
            editInfo: true,
            showModal: true
        })
    }

    onStartEditAccounts = () => {
        this.setState({
            ...this.state,
            editAccounts: true,
            showModal: true
        })
    }

    onCloseModal = () => {
        this.setState({
            ...this.state,
            showModal: false,
            editInfo: false,
            editAccounts: false
        })
    }

    render() {
        let isLoading = this.props.loadingEmployee
        let data = this.props.employee

        if (isLoading) {
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary"/>
                </Grid>
            </Grid>)
        }

        return (!isLoading &&
            <Card className="padding-10-20">
                <Typography variant="h4" gutterBottom>
                    Карточка сотрудника
                </Typography>
                <Grid container spacing={2} direction="row">
                    <Grid item xs={12} sm={8} container direction="column" spacing={2}>
                        <Grid item container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="h5">Основная информация</Typography>
                            </Grid>
                            {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                            (<Grid item>
                                <IconButton onClick={this.onStartEditInfo}>
                                    <Edit/>
                                </IconButton>
                                <Tooltip title="Посмотреть пароль сотрудника">
                                    <IconButton onClick={() => this.showEmployeePassword(data.id)}>
                                        <RemoveRedEye/>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Выслать новый пароль сотруднику">
                                    <IconButton onClick={this.onOpenNewEmployeePassword}>
                                        <Mail/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>)}
                        </Grid>
                        <Grid item xs={10}>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Поле</TableCell>
                                            <TableCell>Значение</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Ф.И.О.</TableCell>
                                            <TableCell>{data.fullName}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Email</TableCell>
                                            <TableCell>{data.email}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Телефон</TableCell>
                                            <TableCell>{data.phone}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Внутренний телефон</TableCell>
                                            <TableCell>{data.internalPhone}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Регион</TableCell>
                                            <TableCell>{data.region}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Организация</TableCell>
                                            <TableCell>{data.organisation}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Отдел</TableCell>
                                            <TableCell>{data.department}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Должность</TableCell>
                                            <TableCell>{data.jobPosition}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Руководитель</TableCell>
                                            <TableCell>{data.chief}</TableCell>
                                        </TableRow>
                                        <TableRow hover className="tableRow">
                                            <TableCell>Комментарий</TableCell>
                                            <TableCell>{data.comment}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={4} container direction="column" spacing={2}>
                        <Grid item container direction="row" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography variant="h5">Учетные записи</Typography>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={this.onStartEditAccounts}>
                                    <Edit/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid item container>
                            {data.accounts.length > 0 ?
                                <Grid item>
                                    <TableContainer>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Система</TableCell>
                                                    <TableCell>Логин</TableCell>
                                                    {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                                    <TableCell>Действие</TableCell>
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.accounts.map((item, index) => (
                                                    <TableRow key={index} hover className="tableRow">
                                                        <TableCell>{item.system}</TableCell>
                                                        <TableCell>{item.login}</TableCell>
                                                        {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                                        <TableCell>
                                                            <Tooltip title="Посмотреть пароль от учетной записи">
                                                                <IconButton onClick={() => this.showAccountPassword(item.id)}>
                                                                    <RemoveRedEye/>
                                                                </IconButton>
                                                            </Tooltip>
                                                            <Tooltip title="Выслать новый пароль для учетной записи">
                                                                <IconButton onClick={() => this.onOpenNewAccountPassword(item.id)}>
                                                                    <Mail/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                                :
                                <Grid item>
                                    <Typography>Учетных записей нет</Typography>
                                </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                <Dialog open={this.state.showNewPasswordModal} onClose={this.closeNewPasswordWindow}>
                    <Card>
                        <CardHeader action={
                            <IconButton onClick={this.closeNewPasswordWindow}>
                                <Close/>
                            </IconButton>
                        }
                        />
                        <CardContent>
                            <Grid container direction="column" alignItems="center" spacing={3}>
                               <Grid item>
                                   <FormControl fullWidth>
                                       <InputLabel htmlFor="standard-adornment-main-password">Пароль</InputLabel>
                                       <Input
                                           id="standard-adornment-main-password"
                                           type={this.state.showNewPassword ? 'text' : 'password'}
                                           value={this.state.newPassword}
                                           onChange={this.onSetNewPassword}
                                           endAdornment={
                                               <InputAdornment position="end">
                                                   <IconButton
                                                       aria-label="toggle password visibility"
                                                       onClick={this.changeModeWriteNewPassword}
                                                   >
                                                       {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                   </IconButton>
                                               </InputAdornment>
                                           }
                                       />
                                   </FormControl>
                               </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => this.sendNewPassword(data.id)}>
                                        <Typography>Отправить</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Dialog>
                <Dialog
                    open={this.state.showPassword}
                    onClose={this.closePasswordWindow}>
                    <Card variant="outlined">
                        <CardHeader action={
                            <IconButton onClick={this.closePasswordWindow}>
                                <Close/>
                            </IconButton>
                        }
                        />
                        <CardContent>
                            <Grid container direction="column" alignItems="center">
                                <Typography>Пароль сотрудника:</Typography>
                                <Typography variant="h6">{this.state.password}</Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </Dialog>
                <Dialog
                    open={this.state.showModal}
                    onClose={this.onCloseModal}
                >
                    <Card variant="outlined">
                        <CardHeader
                            action={
                                <IconButton onClick={this.onCloseModal}>
                                    <Close/>
                                </IconButton>
                            }
                            title={this.editInfo ? 'Редактирование основной информации' : 'Редактирование учетных записей'}
                        />
                        <CardContent>
                            {this.state.editInfo && <EditEmployee data={data}/>}
                            {this.state.editAccounts && <EditAccounts data={data}/>}
                        </CardContent>
                    </Card>
                </Dialog>
            </Card>
        )
    }
}

const mapStateToProps = state => {
    return {
        employee: state.employee.employee,
        loadingEmployee: state.employee.loadingEmployee
    };
};

const mapDispatchToProps = {
    fetchEmployee,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(Employee);