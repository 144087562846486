import {GET_ALL_ROLE, GET_ROLE_LIST, LOADING_ROLE_LIST} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchRoleList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_ROLE_LIST
            })

            let uri = '/roles?'

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(param){
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ROLE_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchAllRole = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_ROLE_LIST
            })

            const response = await httpWrapper('/roles', {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_ROLE,
                payload: result
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}