export const GET_EMPLOYEE_LIST = "EMPLOYEE/GET_LIST";
export const LOADING_EMPLOYEE_LIST = "EMPLOYEE/LOADING_LIST";
export const GET_EMPLOYEE = "EMPLOYEE/GET";
export const LOADING_EMPLOYEE = "EMPLOYEE/LOADING";
export const START_ADD_EMPLOYEE = "EMPLOYEE/STAR_ADD";
export const STOP_ADD_EMPLOYEE = "EMPLOYEE/STOP_ADD";
export const START_UPDATE_EMPLOYEE = "EMPLOYEE/START_UPDATE";
export const STOP_UPDATE_EMPLOYEE = "EMPLOYEE/STOP_UPDATE";
export const START_UPDATE_ACCOUNTS = "EMPLOYEE/START_UPDATE_ACCOUNTS";
export const STOP_UPDATE_ACCOUNTS = "EMPLOYEE/STOP_UPDATE_ACCOUNTS";
export const GET_EMPLOYEE_ACCOUNTS = "EMPLOYEE/GET_ACCOUNTS";
export const LOADING_EMPLOYEE_ACCOUNTS = "EMPLOYEE/LOADING_ACCOUNT_LIST";

export const GET_DEPARTMENT_LIST = "DEPARTMENT/GET_LIST";
export const GET_ALL_DEPARTMENTS = "DEPARTMENT/GET_ALL";
export const LOADING_DEPARTMENT_LIST = "DEPARTMENT/LOADING_LIST";
export const START_UPDATE_DEPARTMENT = "DEPARTMENT/START_UPDATE";
export const STOP_UPDATE_DEPARTMENT = "DEPARTMENT/STOP_UPDATE";
export const START_ADD_DEPARTMENT = "DEPARTMENT/START_ADD";
export const STOP_ADD_DEPARTMENT = "DEPARTMENT/STOP_ADD";
export const START_REMOVE_DEPARTMENT = "DEPARTMENT/START_REMOVE";
export const STOP_REMOVE_DEPARTMENT = "DEPARTMENT/STOP_REMOVE";

export const GET_JOB_POSITION_LIST = "JOB_POSITION/GET_LIST";
export const GET_ALL_JOB_POSITIONS = "JOB_POSITION/GET_ALL";
export const LOADING_JOB_POSITION_LIST = "JOB_POSITION/LOADING_LIST"
export const START_UPDATE_JOB_POSITION = "JOB_POSITION/START_UPDATE";
export const STOP_UPDATE_JOB_POSITION = "JOB_POSITION/STOP_UPDATE";
export const START_ADD_JOB_POSITION = "JOB_POSITION/START_ADD";
export const STOP_ADD_JOB_POSITION = "JOB_POSITION/STOP_ADD";
export const START_REMOVE_JOB_POSITION = "JOB_POSITION/START_REMOVE";
export const STOP_REMOVE_JOB_POSITION = "JOB_POSITION/STOP_REMOVE";

export const GET_ORGANISATION_LIST = "ORGANISATION/GET_LIST";
export const GET_ALL_ORGANISATIONS = "ORGANISATION/GET_ALL";
export const LOADING_ORGANISATION_LIST = "ORGANISATION/LOADING_LIST"
export const START_UPDATE_ORGANISATION = "JOB_POSITION/START_UPDATE";
export const STOP_UPDATE_ORGANISATION = "ORGANISATION/STOP_UPDATE";
export const START_ADD_ORGANISATION = "ORGANISATION/START_ADD";
export const STOP_ADD_ORGANISATION = "ORGANISATION/STOP_ADD";
export const START_REMOVE_ORGANISATION = "ORGANISATION/START_REMOVE";
export const STOP_REMOVE_ORGANISATION = "ORGANISATION/STOP_REMOVE";

export const GET_EMAIL_DOMAIN_LIST = "EMAIL_DOMAIN/GET_LIST";
export const GET_ALL_EMAIL_DOMAINS = "EMAIL_DOMAIN/GET_ALL";
export const LOADING_EMAIL_DOMAIN_LIST = "EMAIL_DOMAIN/LOADING_LIST"
export const START_UPDATE_EMAIL_DOMAIN = "EMAIL_DOMAIN/START_UPDATE";
export const STOP_UPDATE_EMAIL_DOMAIN = "EMAIL_DOMAIN/STOP_UPDATE";
export const START_ADD_EMAIL_DOMAIN = "EMAIL_DOMAIN/START_ADD";
export const STOP_ADD_EMAIL_DOMAIN = "EMAIL_DOMAIN/STOP_ADD";
export const START_REMOVE_EMAIL_DOMAIN = "EMAIL_DOMAIN/START_REMOVE";
export const STOP_REMOVE_EMAIL_DOMAIN = "EMAIL_DOMAIN/STOP_REMOVE";

export const GET_REGION_LIST = "REGION/GET_LIST";
export const GET_ALL_REGIONS = "REGION/GET_ALL";
export const LOADING_REGION_LIST = "REGION/LOADING_LIST"
export const START_UPDATE_REGION = "REGION/START_UPDATE";
export const STOP_UPDATE_REGION = "REGION/STOP_UPDATE";
export const START_ADD_REGION = "REGION/START_ADD";
export const STOP_ADD_REGION = "REGION/STOP_ADD";
export const START_REMOVE_REGION = "REGION/START_REMOVE";
export const STOP_REMOVE_REGION = "REGION/STOP_REMOVE";

export const GET_CORP_SYSTEM_LIST = "CORP_SYSTEM/GET_LIST";
export const GET_ALL_CORP_SYSTEMS = "CORP_SYSTEM/GET_ALL";
export const LOADING_CORP_SYSTEM_LIST = "CORP_SYSTEM/LOADING_LIST"
export const START_UPDATE_CORP_SYSTEM = "CORP_SYSTEM/START_UPDATE";
export const STOP_UPDATE_CORP_SYSTEM = "CORP_SYSTEM/STOP_UPDATE";
export const START_ADD_CORP_SYSTEM = "CORP_SYSTEM/START_ADD";
export const STOP_ADD_CORP_SYSTEM = "CORP_SYSTEM/STOP_ADD";
export const START_REMOVE_CORP_SYSTEM = "CORP_SYSTEM/START_REMOVE";
export const STOP_REMOVE_CORP_SYSTEM = "CORP_SYSTEM/STOP_REMOVE";

export const GET_CHIEF_LIST = "CHIE/GET_LIST";
export const GET_ALL_CHIEF = "CHIE/GET_ALL";
export const LOADING_CHIEF_LIST = "CHIE/LOADING_LIST"

export const GET_USER_LIST = "USER/GET_LIST"
export const START_UPDATE_USER_ROLE = "USER/START_UPDATE_ROLE"
export const STOP_UPDATE_USER_ROLE = "USER/STOP_UPDATE_ROLE"
export const LOADING_USER_LIST = "USER/LOADING_LIST"
export const START_UPDATE_USER = "USER/START_UPDATE";
export const STOP_UPDATE_USER = "USER/STOP_UPDATE";
export const START_ADD_USER = "USER/START_ADD";
export const STOP_ADD_USER = "USER/STOP_ADD";
export const START_REMOVE_USER = "USER/START_REMOVE";
export const STOP_REMOVE_USER = "USER/STOP_REMOVE";

export const GET_ROLE_LIST = "ROLE/GET_LIST"
export const GET_ALL_ROLE = "ROLE/GET_ALL"
export const LOADING_ROLE_LIST = "ROLE/LOADING_LIST"

export const SHOW_ALERT = "ALERT/SHOW"
export const CLOSE_ALERT = "ALERT/CLOSE"