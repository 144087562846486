import {
    GET_USER_LIST,
    LOADING_USER_LIST,
    START_ADD_USER,
    START_REMOVE_USER,
    START_UPDATE_USER, START_UPDATE_USER_ROLE,
    STOP_ADD_USER, STOP_REMOVE_USER,
    STOP_UPDATE_USER, STOP_UPDATE_USER_ROLE
} from "../types";

const initialState = {
    pagination: [],
    userList: [],
    loadingUserList: true,
    editingUser: false,
    addingUser: false,
    removingUser: false,
    editingUserRole: false
}

export const userReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_USER_LIST:
            return {
                ...state,
                userList: action.payload.resources,
                pagination: action.payload.meta.pagination,
                loadingUserList: false
            }
        case LOADING_USER_LIST:
            return {
                ...state,
                loadingUserList: true
            }
        case START_UPDATE_USER:
            return {
                ...state,
                editingUser: true
            }
        case STOP_UPDATE_USER:
            return {
                ...state,
                editingUser: false
            }
        case START_ADD_USER:
            return {
                ...state,
                addingUser: true
            }
        case STOP_ADD_USER:
            return {
                ...state,
                addingUser: false
            }
        case START_REMOVE_USER:
            return {
                ...state,
                removingUser: true
            }
        case STOP_REMOVE_USER:
            return {
                ...state,
                removingUser: false
            }
        case START_UPDATE_USER_ROLE:
            return {
                ...state,
                editingUserRole: true
            }
        case STOP_UPDATE_USER_ROLE:
            return {
                ...state,
                editingUserRole: false
            }
        default:
            return state
    }
}