import React from 'react'
import {connect} from "react-redux";
import {Button, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {updateUser} from "../../redux/actions/userActions";
import {showAlert} from "../../redux/actions/alertActions";
import CircularProgress from "@material-ui/core/CircularProgress";


class UserEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: this.props.data.username,
            fullName: this.props.data.fullName,
            email: this.props.data.email
        }
    }

    save = (strCode) => {
        const data = {
            username: this.state.username,
            fullName: this.state.fullName,
            email: this.state.email,
        }
        this.props.updateUser(data, strCode).then(() => {
            window.location.href = '/users'
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetUsername = (event) => {
        this.setState({
            ...this.state,
            username: event.target.value
        })
    }

    onSetEmail = (event) => {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    onSetFullName = (event) => {
        this.setState({
            ...this.state,
            fullName: event.target.value
        })
    }

    render() {
        let editing = this.props.editingUser

        if(editing){
            return (<Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (
            <Grid container spacing={3} className="padding-10-20">
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Login"
                        fullWidth
                        value={this.state.username}
                        onChange={this.onSetUsername}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Email"
                        fullWidth
                        value={this.state.email}
                        onChange={this.onSetEmail}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Имя"
                        fullWidth
                        value={this.state.fullName}
                        onChange={this.onSetFullName}
                    />
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => this.save(this.props.data.strCode)}>
                            <Typography>Сохранить</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        editingUser: state.user.editingUser
    };
};

const mapDispatchToProps = {
    updateUser,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);