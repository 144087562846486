import {
    GET_ALL_REGIONS,
    GET_REGION_LIST,
    LOADING_REGION_LIST,
    START_REMOVE_REGION,
    START_UPDATE_REGION, STOP_REMOVE_REGION
} from "../types";

const initialState = {
    regionList: [],
    pagination: [],
    allRegions: [],
    loadingRegionList: true,
    editingRegion: false,
    addingRegion: false,
    removingRegion: false
}

export const regionReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_REGION_LIST:
            return {
                ...state,
                regionList: action.payload.resources,
                pagination: action.payload.meta ? action.payload.meta.pagination : [],
                loadingRegionList: false
            }
        case GET_ALL_REGIONS:
            return {
                ...state,
                allRegions: action.payload.resources,
                loadingRegionList: false
            }
        case LOADING_REGION_LIST:
            return {
                ...state,
                loadingRegionList: true
            }
        case START_UPDATE_REGION:
            return {
                ...state,
                editingRegion: true
            }
        case START_REMOVE_REGION:
            return {
                ...state,
                removingRegion: true
            }
        case STOP_REMOVE_REGION:
            return {
                ...state,
                removingRegion: false
            }
        default:
            return state
    }
}