import React from 'react'
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {connect} from "react-redux";
import {closeAlert, showAlert} from "../redux/actions/alertActions";

class AlertMessage extends React.Component {
    render() {
        return (
            <Snackbar open={this.props.open} autoHideDuration={4000} onClose={this.props.closeAlert}>
                <Alert onClose={this.props.closeAlert} severity={this.props.type}>
                    {this.props.message}
                </Alert>
            </Snackbar>
        )
    }

}

const mapStateToProps = state => {
    return {
        open: state.alert.open,
        message: state.alert.message,
        type: state.alert.type,
    };
};

const mapDispatchToProps = {
    showAlert,
    closeAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);