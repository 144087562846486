import React from "react";
import AuthService from "../services/AuthService";
import {Button, Grid, Typography} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import {showAlert} from "../redux/actions/alertActions";
import {connect} from "react-redux";
import logo from '../logo.png'


class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {username: "", password: ""};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        AuthService.signIn(this.state.username,this.state.password).then((r) => {
            window.location.href = '/'
        }).catch((e) => {
            this.props.showAlert(e.message, true);
        })
    }

    render() {
        return (
            <Grid container justify="flex-start" alignItems="center" direction="column" className="height-100 width-100">
                <Grid item xs={12}>
                    <Card variant="elevation" className="padding-10-20">
                        <Grid container alignItems="center" justify="space-between" direction="column">
                            <Grid item xs={12}>
                                <img src={logo} alt="logo" className="logo" />
                            </Grid>
                            <Grid item xs={12} container alignItems="center" direction="column">
                                <Grid item>
                                    <Typography variant="h5" color="primary">Система Сотрудник</Typography>
                                </Grid>
                                <Grid item>
                                    <form onSubmit={this.handleSubmit}>
                                        <Grid container direction="column" alignItems="center" spacing={3}>
                                            <Grid item>
                                                <TextField
                                                    required
                                                    name="username"
                                                    label="Логин"
                                                    fullWidth
                                                    value={this.state.username}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    required
                                                    type="password"
                                                    name="password"
                                                    label="Пароль"
                                                    fullWidth
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="primary" type="submit">
                                                    войти
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
