import {
    GET_ALL_CORP_SYSTEMS,
    GET_CORP_SYSTEM_LIST,
    LOADING_CORP_SYSTEM_LIST, START_ADD_CORP_SYSTEM, START_REMOVE_CORP_SYSTEM,
    START_UPDATE_CORP_SYSTEM, STOP_ADD_CORP_SYSTEM, STOP_REMOVE_CORP_SYSTEM,
    STOP_UPDATE_CORP_SYSTEM
} from "../types";

const initialState = {
    corpSystemList: [],
    allCorpSystems: [],
    pagination: [],
    loadingCorpSystemList: true,
    editingCorpSystem: false,
    addingCorpSystem: false,
    removingCorpSystem: false
}

export const corpSystemReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_CORP_SYSTEM_LIST:
            return {
                ...state,
                corpSystemList: action.payload.resources,
                pagination: action.payload.meta.pagination,
                loadingCorpSystemList: false
            }
        case GET_ALL_CORP_SYSTEMS:
            return {
                ...state,
                allCorpSystems: action.payload.resources,
                loadingCorpSystemList: false
            }
        case LOADING_CORP_SYSTEM_LIST:
            return {
                ...state,
                loadingCorpSystemList: true
            }
        case START_UPDATE_CORP_SYSTEM:
            return {
                ...state,
                editingCorpSystem: true
            }
        case STOP_UPDATE_CORP_SYSTEM:
            return {
                ...state,
                editingCorpSystem: false
            }
        case START_ADD_CORP_SYSTEM:
            return {
                ...state,
                addingCorpSystem: true
            }
        case STOP_ADD_CORP_SYSTEM:
            return {
                ...state,
                addingCorpSystem: false
            }
        case START_REMOVE_CORP_SYSTEM:
            return {
                ...state,
                removingCorpSystem: true
            }
        case STOP_REMOVE_CORP_SYSTEM:
            return {
                ...state,
                removingCorpSystem: false
            }
        default:
            return state
    }
}