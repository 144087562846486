import {
    GET_USER_LIST,
    LOADING_USER_LIST,
    START_ADD_USER, START_REMOVE_USER,
    START_UPDATE_USER, START_UPDATE_USER_ROLE,
    STOP_ADD_USER, STOP_REMOVE_USER,
    STOP_UPDATE_USER, STOP_UPDATE_USER_ROLE
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchUserList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_USER_LIST
            })

            let uri = '/users?'

            if (page && perPage) {
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if (param) {
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_USER_LIST,
                payload: result
            })

        } catch (e) {

            throw new Error(e.message)

        }
    }
}

export const addUser = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_USER
            })
            const response = await httpWrapper('/users', {
                method: 'post',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }

            const result = await response.json()
            dispatch({
                type: STOP_ADD_USER
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateUser = (data, strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_USER
            })
            const response = await httpWrapper(`/users/${strCode}`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_USER
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const activateUser = (strCode) => {
    return async dispatch => {
        try {
            const response = await httpWrapper(`/users/${strCode}/activate`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const blockUser = (strCode) => {
    return async dispatch => {
        try {
            const response = await httpWrapper(`/users/${strCode}/block`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const removeUser = (strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_REMOVE_USER
            })
            const response = await httpWrapper(`/users/${strCode}`, {
                method: 'delete',
                credentials: "include", mode: "cors"
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_REMOVE_USER
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateUserRole = (data, userCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_USER_ROLE
            })
            const response = await httpWrapper(`/users/${userCode}/roles`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_USER_ROLE
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}