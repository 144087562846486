import {combineReducers} from "redux";
import {employeeReducer} from "./reducers/employeeReducer";
import {regionReducer} from "./reducers/regionReducer";
import {emailDomainReducer} from "./reducers/emailDomainReducer";
import {jobPositionReducer} from "./reducers/jobPositionReducer";
import {departmentReducer} from "./reducers/departmentReducer";
import {organisationReducer} from "./reducers/organisationReducer";
import {corpSystemReducer} from "./reducers/corpSystemReducer";
import {chiefReducer} from "./reducers/chiefReducer";
import {userReducer} from "./reducers/userReducer";
import {roleReducer} from "./reducers/roleReducer";
import {alertReducer} from "./reducers/alertReducer";


export const rootReducer = combineReducers({
    employee: employeeReducer,
    region: regionReducer,
    emailDomain: emailDomainReducer,
    jobPosition: jobPositionReducer,
    department: departmentReducer,
    organisation: organisationReducer,
    corpSystem: corpSystemReducer,
    chief: chiefReducer,
    user: userReducer,
    role: roleReducer,
    alert: alertReducer
})