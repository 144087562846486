import {
    CLOSE_ALERT, SHOW_ALERT
} from "../types";

const initialState = {
    open: false,
    message: '',
    type: 'success'
}

export const alertReducer = (state = initialState, action) => {
    switch (action.type){
        case SHOW_ALERT:
            return {
                open: true,
                message: action.payload.message,
                type: action.payload.typeMessage ? 'error' : 'success'
            }
        case CLOSE_ALERT:
            return {
                open: false,
                message: ''
            }
        default:
            return state
    }
}