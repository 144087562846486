import {API_URL} from "../config";

class AuthService{

    currentUser = () => {

        if(!localStorage.getItem('username')){
            return false;
        }

        return {
            username: localStorage.getItem('username'),
            roles: JSON.parse(localStorage.getItem('roles'))
        }
    }

    checkPermission = (role) => {
        const roles = this.currentUser().roles

        if(!roles){
            return false;
        }

        if(roles.includes('ROOT_ROLE')){
            return true
        }

        return roles.includes(role)
    }

    signIn = async (username, password) => {
        try{
            const response = await fetch(API_URL + "/login", {
                method: 'post',
                credentials: "include",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                })
            })

            if(response.status !== 200){
                const error = await response.json()
                throw new Error(error.message)
            }

            const result = await response.json();
            localStorage.setItem('username', result.username)
            localStorage.setItem('roles', JSON.stringify(result.roles))

        }catch (e){
            throw new Error(e.message)
        }
    }
}

export default new AuthService();
