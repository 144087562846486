import {
    GET_EMPLOYEE, GET_EMPLOYEE_ACCOUNTS,
    GET_EMPLOYEE_LIST,
    START_ADD_EMPLOYEE,
    LOADING_EMPLOYEE, LOADING_EMPLOYEE_ACCOUNTS, START_UPDATE_ACCOUNTS, START_UPDATE_EMPLOYEE,
    STOP_ADD_EMPLOYEE, STOP_UPDATE_ACCOUNTS, STOP_UPDATE_EMPLOYEE, LOADING_EMPLOYEE_LIST
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchEmployeeList = (page = null, perPage = null, params = null, searchParam = null, reload) => {
    return async dispatch => {
        try {
            if(reload){
                dispatch({
                    type: LOADING_EMPLOYEE_LIST
                })
            }

            let uri = `/employees?`;

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(params){
                params.forEach((item) => {
                    if(item.param){
                        uri += `&${item.typeParam}=${item.param}`;
                    }
                })
            }
            if(searchParam){
                uri += `&${searchParam.typeParam}=${searchParam.param}`;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_EMPLOYEE_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchEmployee = (id) => {
    return async dispatch => {
        try {

            dispatch({
                type: LOADING_EMPLOYEE
            })

            let uri = `/employees/${id}`;

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_EMPLOYEE,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchEmployeeAccounts = (id) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_EMPLOYEE_ACCOUNTS
            })

            let uri = `/employees/${id}/accounts`;

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_EMPLOYEE_ACCOUNTS,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const addEmployee = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_EMPLOYEE
            })


            const response = await httpWrapper('/employees',
                {
                    method: 'post',
                    credentials: "include", mode: "cors",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

            if(!response.ok){
                const error = await response.json()
                throw new Error(error.message)
            }

            const result = await response.json()

            dispatch({
                type: STOP_ADD_EMPLOYEE
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const updateEmployee = (data, id) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_EMPLOYEE
            })

            const response = await httpWrapper(`/employees/${id}`,
                {
                    method: 'put',
                    credentials: "include", mode: "cors",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

            if(!response.ok){
                const error = await response.json()
                throw new Error(error.message)
            }

            await response.json()

            dispatch({
                type: STOP_UPDATE_EMPLOYEE
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const updateAccounts = (data, id) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_ACCOUNTS
            })

            const response = await httpWrapper(`/employees/${id}/accounts`,
                {
                    method: 'put',
                    credentials: "include", mode: "cors",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                });

            if(!response.ok){
                const error = await response.json()
                throw new Error(error.message)
            }

            await response.json()

            dispatch({
                type: STOP_UPDATE_ACCOUNTS
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}