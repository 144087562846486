import {
    GET_ALL_DEPARTMENTS,
    GET_DEPARTMENT_LIST,
    LOADING_DEPARTMENT_LIST, START_ADD_DEPARTMENT, START_REMOVE_DEPARTMENT,
    START_UPDATE_DEPARTMENT, STOP_ADD_DEPARTMENT, STOP_REMOVE_DEPARTMENT,
    STOP_UPDATE_DEPARTMENT
} from "../types";

const initialState = {
    departmentList: [],
    pagination: [],
    allDepartments: [],
    loadingDepartmentList: true,
    editingDepartment: false,
    addingDepartment: false,
    removingDepartment: false
}

export const departmentReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_DEPARTMENT_LIST:
            return {
                ...state,
                departmentList: action.payload.resources,
                pagination: action.payload.meta ? action.payload.meta.pagination : [],
                loadingDepartmentList: false
            }
        case GET_ALL_DEPARTMENTS:
            return {
                ...state,
                allDepartments: action.payload.resources,
                loadingDepartmentList: false
            }
        case LOADING_DEPARTMENT_LIST:
            return {
                ...state,
                loadingDepartmentList: true
            }
        case START_UPDATE_DEPARTMENT:
            return {
                ...state,
                editingDepartment: true
            }
        case STOP_UPDATE_DEPARTMENT:
            return {
                ...state,
                editingDepartment: false
            }
        case START_ADD_DEPARTMENT:
            return {
                ...state,
                addingDepartment: true
            }
        case STOP_ADD_DEPARTMENT:
            return {
                ...state,
                addingDepartment: false
            }
        case START_REMOVE_DEPARTMENT:
            return {
                ...state,
                removingDepartment: true
            }
        case STOP_REMOVE_DEPARTMENT:
            return {
                ...state,
                removingDepartment: false
            }
        default:
            return state
    }
}