import {
    GET_ALL_REGIONS,
    GET_REGION_LIST,
    LOADING_REGION_LIST,
    START_ADD_REGION, START_REMOVE_REGION,
    START_UPDATE_REGION,
    STOP_ADD_REGION, STOP_REMOVE_REGION, STOP_UPDATE_REGION
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchRegionList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_REGION_LIST
            })

            let uri = `/regions?`;

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(param){
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_REGION_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchAllRegions = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_REGION_LIST
            })

            const response = await httpWrapper('/regions', {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_REGIONS,
                payload: result
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const addRegion = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_REGION
            })
            const response = await httpWrapper('/regions', {
                method: 'post',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_ADD_REGION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateRegion = (data, strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_REGION
            })
            const response = await httpWrapper(`/regions/${strCode}`, {
                method: 'put',
                credentials: "include", mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_REGION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const removeRegion = (strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_REMOVE_REGION
            })
            const response = await httpWrapper(`/regions/${strCode}`, {
                method: 'delete',
                credentials: "include", mode: "cors"
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_REMOVE_REGION
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}