import React from "react";
import {connect} from "react-redux";
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Chip,
    Card, IconButton, CardHeader, CardContent, Typography
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import CardActions from "@material-ui/core/CardActions";
import {
    Add, Close, Delete, Edit, Mail, Visibility, VisibilityOff,
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {activateUser, blockUser, fetchUserList, removeUser, updateUser} from "../../redux/actions/userActions";
import Dialog from "@material-ui/core/Dialog";
import AddUser from "./AddUser";
import UserEdit from "./UserEdit";
import UserRoleEdit from "./UserRoleEdit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {httpWrapper} from "../../libs/http";
import {showAlert} from "../../redux/actions/alertActions";


class UserList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            showModal: false,
            creating: false,
            userEditing: false,
            userRoleEditing: false,
            data: null,
            showNewPasswordModal: false,
            showNewPassword: false,
            newPassword: '',
            strCode: ''
        }
    }

    componentDidMount() {
        this.props.fetchUserList(this.state.page + 1, this.state.rowsPerPage)
    }
    sendNewPassword = async (strCode) => {
        try{
            let uri = `/users/${strCode}/change_password`
            const response = await httpWrapper(uri,
                {
                    credentials: "include",
                    method: 'put',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        password: this.state.newPassword
                    })
                })
            if(!response.ok){
                throw new Error("Не удалось изменить пароль")
            }
            const result = await response.json()
            this.closeNewPasswordWindow()
            this.props.showAlert("Новый пароль успешно отправлен на почту")
        }catch (e){
            this.props.showAlert(e.message, true)
        }
    }
    onSetNewPassword = (event) => {
        this.setState({
            ...this.state,
            newPassword: event.target.value
        })
    }
    onOpenNewPassword = (strCode) => {
        this.setState({
            ...this.state,
            strCode: strCode,
            showNewPasswordModal: true
        })
    }
    changeModeWriteNewPassword = () => {
        this.setState({
            ...this.state,
            showNewPassword: !this.state.showNewPassword
        })
    }
    closeNewPasswordWindow = () => {
        this.setState({
            ...this.state,
            newPassword: '',
            showNewPasswordModal: false,
            showNewPassword: false
        })
    }

    onStartCreate = () => {
        this.setState({
            ...this.state,
            showModal: true,
            creating: true
        })
    }

    onStartUserEdit = (user) => {
        this.setState({
            ...this.state,
            showModal: true,
            userEditing: true,
            data: user
        })
    }

    onStartUserRoleEdit = (user) => {
        this.setState({
            ...this.state,
            showModal: true,
            userRoleEditing: true,
            data: user
        })
    }

    onCloseModal = () => {
        this.setState({
            ...this.state,
            showModal: false,
            creating: false,
            userEditing: false,
            userRoleEditing: false
        })
    }

    onRemoveUser = (strCode) => {
        this.props.removeUser(strCode).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            ...this.state,
            page: newPage
        }, () => {
            this.props.fetchUserList(this.state.page + 1, this.state.rowsPerPage)
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            ...this.state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, () => {
            this.props.fetchUserList(this.state.page + 1, this.state.rowsPerPage)
        })
    }

    activate = (strCode) => {
        this.props.activateUser(strCode).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message)
        })
    }

    block = (strCode) => {
        this.props.blockUser(strCode).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message)
        })
    }

    render() {
        const isLoading = this.props.loadingUserList

        if (isLoading) {
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary"/>
                </Grid>
            </Grid>)
        }

        return (
            <Grid container direction="column" component={Card}>
                <CardActions disableSpacing>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item>
                            <Tooltip title="добавить новый отдел">
                                <Button variant="contained" aria-label="share" onClick={this.onStartCreate}>
                                    <Add/>
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardActions>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Логин</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Роли</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell>Действия</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.userList.map((item, index) => (
                                <TableRow hover key={index} className="tableRow">
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.username}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>
                                        <Grid container direction="row">
                                            <Grid item xs={10} container direction="row" alignItems="center">
                                                {
                                                    item.roles.map((role, index) => (
                                                        <Grid item key={index}>
                                                            <Chip size="small" label={role.name} color="primary"/>
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                            <Grid item xs={2}>
                                                <IconButton onClick={() => this.onStartUserRoleEdit(item)}>
                                                    <Edit/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        {item.status ?
                                            <Chip size="medium" label="активен" clickable color="primary" onDelete={() => this.block(item.strCode)}/> :
                                            <Chip size="medium" label="неактивен" clickable color="secondary" onDelete={() => this.activate(item.strCode)}/>}
                                    </TableCell>
                                    <TableCell>
                                        <IconButton  onClick={() => this.onStartUserEdit(item)}>
                                            <Edit/>
                                        </IconButton>
                                        <IconButton  onClick={() => this.onRemoveUser(item.strCode)}>
                                            <Delete/>
                                        </IconButton>
                                        <Tooltip title="Выслать новый пароль для учетной записи">
                                            <IconButton onClick={() => this.onOpenNewPassword(item.strCode)}>
                                                <Mail/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.props.pagination.total}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Dialog open={this.state.showModal} onClose={this.onCloseModal}>
                    <Card variant="outlined">
                        <CardHeader title="Создание пользователя" action={
                            <IconButton onClick={this.onCloseModal}>
                                <Close/>
                            </IconButton>}/>
                        {this.state.creating && <AddUser/>}
                        {this.state.userEditing && <UserEdit data={this.state.data}/>}
                        {this.state.userRoleEditing && <UserRoleEdit data={this.state.data}/>}
                    </Card>
                </Dialog>
                <Dialog open={this.state.showNewPasswordModal} onClose={this.closeNewPasswordWindow}>
                    <Card>
                        <CardHeader action={
                            <IconButton onClick={this.closeNewPasswordWindow}>
                                <Close/>
                            </IconButton>
                        }
                        />
                        <CardContent>
                            <Grid container direction="column" alignItems="center" spacing={3}>
                                <Grid item>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="standard-adornment-main-password">Пароль</InputLabel>
                                        <Input
                                            id="standard-adornment-main-password"
                                            type={this.state.showNewPassword ? 'text' : 'password'}
                                            value={this.state.newPassword}
                                            onChange={this.onSetNewPassword}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={this.changeModeWriteNewPassword}
                                                    >
                                                        {this.state.showNewPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" onClick={() => this.sendNewPassword(this.state.strCode)}>
                                        <Typography>Отправить</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Dialog>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        userList: state.user.userList,
        pagination: state.user.pagination,
        loadingUserList: state.user.loadingUserList,
    };
};

const mapDispatchToProps = {
    fetchUserList,
    updateUser,
    removeUser,
    activateUser,
    blockUser,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);