import {API_URL} from "../config";

export const httpWrapper = async (path, init) => {
    try{
        const response = await fetch(API_URL + path, init)

        if(response.status === 401){
            await refreshToken()
            return await fetch(API_URL + path, init)
        }

        if(response.status === 403){
            localStorage.removeItem('username')
            localStorage.removeItem('roles')
            window.location.reload()
        }

        return response

    }catch (e) {
        throw new Error(e.message)
    }
}

export const refreshToken = async () => {
    let response = await fetch(API_URL + '/token/refresh', {
        method: 'POST',
        credentials: 'include',
        mode: "cors",
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    if(!response.ok){
        localStorage.removeItem('username')
        localStorage.removeItem('roles')
        window.location.reload()
    }
    await response.json();
}
