import {httpWrapper} from "../../libs/http";
import {GET_ALL_CHIEF, LOADING_CHIEF_LIST} from "../types";

export const fetchAllChief = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_CHIEF_LIST
            })

            let uri = `/employees?isChief=true`;


            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_CHIEF,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}