import React from 'react'
import {connect} from "react-redux";
import {Button, Card, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import {addUser} from "../../redux/actions/userActions";
import {showAlert} from "../../redux/actions/alertActions";
import CircularProgress from "@material-ui/core/CircularProgress";


class AddUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            fullName: '',
            email: '',
            showPassword: false
        }
    }

    addUser = () => {
        const data = {
            username: this.state.username,
            password: this.state.password,
            fullName: this.state.fullName,
            email: this.state.email,
        }
        this.props.addUser(data).then(() => {
            window.location.href = '/users'
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetUsername = (event) => {
        this.setState({
            ...this.state,
            username: event.target.value
        })
    }

    onSetEmail = (event) => {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    onSetFullName = (event) => {
        this.setState({
            ...this.state,
            fullName: event.target.value
        })
    }

    onSetPassword = (event) => {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }

    changeModeWritePassword = () => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword
        })
    }

    render() {
        let adding = this.props.addingUser

        if(adding){
            return (<Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (
            <Grid container spacing={3} className="padding-10-20">
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Login"
                        fullWidth
                        value={this.state.username}
                        onChange={this.onSetUsername}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-main-password">Пароль</InputLabel>
                        <Input
                            id="standard-adornment-main-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.onSetPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={this.changeModeWritePassword}
                                    >
                                        {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Email"
                        fullWidth
                        value={this.state.email}
                        onChange={this.onSetEmail}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Имя"
                        fullWidth
                        value={this.state.fullName}
                        onChange={this.onSetFullName}
                    />
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={this.addUser}>
                            <Typography>Добавить пользователя</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        addingUser: state.user.addingUser
    };
};

const mapDispatchToProps = {
    addUser,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);