import React from 'react'
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Add, Delete, Visibility, VisibilityOff} from "@material-ui/icons";
import {Button, List, ListItem, Typography} from "@material-ui/core";
import {fetchAllCorpSystem} from "../../redux/actions/corpSystemActions";
import {fetchEmployeeAccounts, updateAccounts} from "../../redux/actions/employeeActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showAlert} from "../../redux/actions/alertActions";

class EditAccounts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            corpSystemCode: '',
            login: '',
            password: '',
            accounts: [],
            showPassword: false
        }
    }

    componentDidMount() {
        this.props.fetchAllCorpSystem().catch(e => this.props.showAlert(e.message, true))
        this.props.fetchEmployeeAccounts(this.props.data.id).then(() => {
            this.setState({
                ...this.state,
                accounts: this.props.employeeAccountList
            })
        }).catch(e => this.props.showAlert(e.message, true))
    }

    saveAccounts = () => {
        const data = {
            accounts: this.state.accounts
        }

        this.props.updateAccounts(data, this.props.data.id).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetCorpSystemCode = (event) => {
        this.setState({
            ...this.state,
            corpSystemCode: event.target.value
        })
    }

    onSetLogin = (event) => {
        this.setState({
            ...this.state,
            login: event.target.value
        })
    }

    onSetPassword = (event) => {
        this.setState({
            ...this.state,
            password: event.target.value
        })
    }

    onAddAccount = () => {
        this.setState({
            ...this.state,
            accounts: this.state.accounts.push({
                corpSystemCode: this.state.corpSystemCode,
                login: this.state.login,
                password: this.state.password,
            })
        })
        this.setState({
            ...this.state,
            corpSystemCode: '',
            login: '',
            password: '',
        })
    }

    changeModeWritePassword = () => {
        this.setState({
            ...this.state,
            showPassword: !this.state.showPassword
        })
    }

    onRemoveAccount = (index) => {
        let accounts = this.state.accounts;
        accounts.splice(index, 1)
        this.setState({
            accounts: accounts
        })
    }

    render() {
        const isLoading = this.props.loadingEmployeeAccounts
            && this.props.loadingCorpSystemList

        let isEditing = this.props.editingAccounts

        if (isLoading || isEditing) {
            return (<Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (!isLoading && !isEditing &&
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Система"
                        value={this.state.corpSystemCode}
                        onChange={this.onSetCorpSystemCode}
                    >
                        {this.props.allCorpSystems.map((option) => (
                            <option key={option.id} value={option.strCode} className="option">
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        required
                        label="Логин"
                        fullWidth
                        value={this.state.login}
                        onChange={this.onSetLogin}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="standard-adornment-password">Пароль</InputLabel>
                        <Input
                            id="standard-adornment-password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={this.state.password}
                            onChange={this.onSetPassword}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={this.changeModeWritePassword}
                                    >
                                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <Button color="primary" variant="contained" onClick={this.onAddAccount}>
                        <Add/>
                    </Button>
                </Grid>
                {this.state.accounts.length > 0 &&
                <Grid item xs={12}>
                    <List>
                        {this.state.accounts.map((item, index) => (
                            <ListItem key={index} divider>
                                <Grid container direction="row" alignItems="flex-end" justify="space-between" spacing={2}>
                                    <Grid item xs={8} container direction="row" justify="space-between" spacing={2}>
                                        <Grid item xs={6}>
                                            <Typography color="secondary">
                                                {item.corpSystemCode}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color="primary">
                                                {item.login}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} container justify="flex-end">
                                        <Button color="primary" variant="outlined" onClick={() => this.onRemoveAccount(index)}>
                                            <Delete />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ListItem>
                        ))}
                    </List>
                </Grid>}
                <Grid item xs={12} container justify="center">
                    <Button color="primary" variant="outlined" onClick={this.saveAccounts}>
                        <Typography>Сохранить</Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        allCorpSystems: state.corpSystem.allCorpSystems,
        loadingCorpSystemList: state.corpSystem.loadingCorpSystemList,
        employeeAccountList: state.employee.employeeAccountList,
        loadingEmployeeAccounts: state.employee.loadingEmployeeAccounts,
        editingAccounts: state.employee.editingAccounts
    };
};

const mapDispatchToProps = {
    fetchAllCorpSystem,
    fetchEmployeeAccounts,
    updateAccounts,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAccounts);