import React from "react";
import {connect} from "react-redux";
import {
    Grid,
    Table,
    TableContainer,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Card, IconButton, CardHeader, CardContent
} from "@material-ui/core";
import TablePagination from "@material-ui/core/TablePagination";
import CardActions from "@material-ui/core/CardActions";
import {
    Add, Close, Delete, Edit
} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import {
    addDepartment,
    fetchDepartmentList,
    removeDepartment,
    updateDepartment
} from "../../redux/actions/departmentActions";
import FormEditEntity from "../../components/FormEditEntity";
import FormAddEntity from "../../components/FormAddEntity";
import Dialog from "@material-ui/core/Dialog";
import AuthService from "../../services/AuthService";
import {showAlert} from "../../redux/actions/alertActions";


class DepartmentList extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            edit: false,
            create: false,
            name: '',
            strCode: '',
            showModal: false
        }
    }

    componentDidMount() {
        this.props.fetchDepartmentList(this.state.page+1, this.state.rowsPerPage).catch(e => this.props.showAlert(e.message, true))
    }

    onStartEdit = (entity) => {
        this.setState({
            ...this.state,
            edit: true,
            name: entity.name,
            strCode: entity.strCode,
            showModal: true
        })
    }

    onStartCreate = () => {
        this.setState({
            ...this.state,
            create: true,
            showModal: true
        })
    }

    onCloseModal = () => {
        this.setState({
            ...this.state,
            create: false,
            edit: false,
            name: '',
            strCode: '',
            showModal: false
        })
    }

    onRemove = (strCode) => {
        this.props.removeDepartment(strCode).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            ...this.state,
            page: newPage
        }, () => {
            this.props.fetchDepartmentList(this.state.page+1, this.state.rowsPerPage).catch(e => this.props.showAlert(e.message, true))
        })
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            ...this.state,
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        }, () => {
            this.props.fetchDepartmentList(this.state.page+1, this.state.rowsPerPage).catch(e => this.props.showAlert(e.message, true))
        })
    }

    render() {
        const isLoading = this.props.loadingDepartmentList

        if (isLoading) {
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (
            <Grid container direction="column" component={Card}>
                <CardActions disableSpacing>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item>
                            <Tooltip title="добавить новый отдел">
                                <Button variant="contained" aria-label="share" onClick={this.onStartCreate}>
                                    <Add />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </CardActions>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Отдел</TableCell>
                                <TableCell>Строковый код</TableCell>
                                {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                <TableCell>Действия</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.departmentList.map((item, index) => (
                                <TableRow hover key={index} className="tableRow">
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.strCode}</TableCell>
                                    {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                                    <TableCell>
                                        <IconButton onClick={() => this.onStartEdit(item)}>
                                            <Edit/>
                                        </IconButton>
                                        {AuthService.checkPermission('ROOT_ROLE') &&
                                        <IconButton onClick={() => this.onRemove(item.strCode)}>
                                            <Delete/>
                                        </IconButton>
                                        }
                                    </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={this.props.pagination.total}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
                <Dialog
                    onClose={this.onCloseModal}
                    open={this.state.showModal}
                >
                    <Card variant="outlined">
                        <CardHeader
                            action={
                                <IconButton onClick={this.onCloseModal}>
                                    <Close />
                                </IconButton>
                            }
                            title={this.state.edit ? "Редактирование отдела" : "Добавление отдела"}
                        />
                        <CardContent>
                        {this.state.edit && <FormEditEntity name={this.state.name}
                                                            strCode={this.state.strCode}
                                                            sendForm={this.props.updateDepartment}
                                                            cancelForm={this.onCloseModal} urlReload="/departments"
                                                            editing={this.props.editingDepartment}/>}
                        {this.state.create && <FormAddEntity sendForm={this.props.addDepartment}
                                                             cancelForm={this.onCloseModal} urlReload="/departments"
                                                             adding={this.props.addingDepartment} />}
                        </CardContent>
                    </Card>
                </Dialog>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        departmentList: state.department.departmentList,
        loadingDepartmentList: state.department.loadingDepartmentList,
        pagination: state.department.pagination,
        editingDepartment: state.department.editingDepartment,
        addingDepartment: state.department.addingDepartment
    };
};

const mapDispatchToProps = {
    fetchDepartmentList,
    addDepartment,
    updateDepartment,
    removeDepartment,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentList);