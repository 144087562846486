import React from 'react'
import {fetchEmployeeList, updateEmployee} from "../../redux/actions/employeeActions";
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {fetchAllDepartments} from "../../redux/actions/departmentActions";
import {fetchAllRegions} from "../../redux/actions/regionActions";
import {fetchAllOrganisations} from "../../redux/actions/organisationActions";
import {fetchAllEmailDomains} from "../../redux/actions/emailDomainActions";
import {fetchAllJobPositions} from "../../redux/actions/jobPositionActions";
import {InternalPhoneMask, PhoneMask} from "../../components/PhoneMask";
import {slugify} from "../../libs/sluger";
import {Button, Typography} from "@material-ui/core";
import {fetchAllChief} from "../../redux/actions/cheifActions";
import CircularProgress from "@material-ui/core/CircularProgress";

class EditEmployee extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: this.props.data.fullName,
            email: this.props.data.email ? this.props.data.email.split('@')[0] : '',
            emailDomain: this.props.data.email ? '@'+this.props.data.email.split('@')[1] : '',
            internalPhone: this.props.data.internalPhone ?? '',
            phone: this.props.data.phone ?? '',
            regionCode: this.props.data.regionCode ?? '',
            departmentCode: this.props.data.departmentCode ?? '',
            jobPositionCode: this.props.data.jobPositionCode ?? '',
            organisationCode: this.props.data.organisationCode ?? '',
            comment: this.props.data.comment ?? '',
            isChief: this.props.data.isChief ?? '',
            chiefId: this.props.data.chiefId ?? ''
        }
    }

    componentDidMount() {
        this.props.fetchAllRegions()
        this.props.fetchAllDepartments()
        this.props.fetchAllOrganisations()
        this.props.fetchAllEmailDomains()
        this.props.fetchAllJobPositions()
        this.props.fetchAllChief()
    }

    saveEmployee = () => {
        const data = {
            fullName: this.state.fullName,
            email: this.state.email + this.state.emailDomain,
            internalPhone: this.state.internalPhone,
            phone: this.state.phone,
            regionCode: this.state.regionCode,
            departmentCode: this.state.departmentCode,
            jobPositionCode: this.state.jobPositionCode,
            organisationCode: this.state.organisationCode,
            comment: this.state.comment,
            isChief: this.state.isChief,
            chiefId: this.state.chiefId
        }

        this.props.updateEmployee(data, this.props.data.id).then(() => {
            window.location.reload()
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetEmail = (event) => {
        this.setState({
            ...this.state,
            email: event.target.value
        })
    }

    onSetFullName = (event) => {
        let fullName = event.target.value;
        let fioArray = fullName.split(' ');
        let firstName = fioArray[0] ? slugify(fioArray[0].toLowerCase()) : '';
        let middleName = fioArray[1] ? slugify(fioArray[1][0].toLowerCase()) : '';
        let lastName = fioArray[2] ? slugify(fioArray[2][0].toLowerCase()) : '';
        let email = firstName + '.' + middleName + lastName;
        this.setState({
            ...this.state,
            fullName: fullName,
            email: email
        })
    }

    onSetEmailDomain = (event) => {
        this.setState({
            ...this.state,
            emailDomain: event.target.value
        })
    }

    onSetRegionCode = (event) =>{
        this.setState({
            ...this.state,
            regionCode: event.target.value
        })
    }
    onSetJobPositionCode = (event) =>{
        this.setState({
            ...this.state,
            jobPositionCode: event.target.value
        })
    }
    onSetDepartmentCode = (event) =>{
        this.setState({
            ...this.state,
            departmentCode: event.target.value
        })
    }
    onSetOrganisationCode = (event) =>{
        this.setState({
            ...this.state,
            organisationCode: event.target.value
        })
    }

    onSetComment = (event) => {
        this.setState({
            ...this.state,
            comment: event.target.value
        })
    }

    onSetPhone = (event) => {
        this.setState({
            ...this.state,
            phone: event.target.value
        })
    }

    onSetInternalPhone = (event) => {
        this.setState({
            ...this.state,
            internalPhone: event.target.value
        })
    }

    onSetIsChief = () => {
        this.setState({
            ...this.state,
            isChief: !this.state.isChief
        })
    }

    onSetChief = (event) => {
        this.setState({
            ...this.state,
            chiefId: event.target.value
        })
    }

    render() {
        let isLoading = this.props.loadingChiefList
            && this.props.loadingRegionList
            && this.props.loadingDepartmentList
            && this.props.loadingEmailDomainList
            && this.props.loadingJobPositionList
            && this.props.loadingOrganisationList

        let isEditing = this.props.editingEmployee

        if (isLoading || isEditing) {
            return (<Grid container justify="center" alignItems="center" className="height-100 width-100">
                <Grid item>
                    <CircularProgress color="primary"/>
                </Grid>
            </Grid>)
        }

        return (!isLoading && !isEditing &&
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        label="Ф.И.О"
                        fullWidth
                        value={this.state.fullName}
                        onChange={this.onSetFullName}
                    />
                </Grid>
                <Grid item sm={6} container direction="row">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            label="email"
                            fullWidth
                            value={this.state.email}
                            onChange={this.onSetEmail}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            fullWidth
                            select
                            label="Домены"
                            value={this.state.emailDomain}
                            onChange={this.onSetEmailDomain}
                        >
                            {this.props.allEmailDomains.map((option) => (
                                <option key={option.id} value={option.name} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="formatted-text-mask-input-phone">Личный телефон</InputLabel>
                        <Input
                            value={this.state.phone}
                            onChange={this.onSetPhone}
                            name="phone"
                            id="formatted-text-mask-input-phone"
                            inputComponent={PhoneMask}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="formatted-text-mask-input-internal-phone">Внутренний телефон</InputLabel>
                        <Input
                            value={this.state.internalPhone}
                            onChange={this.onSetInternalPhone}
                            name="internalPhone"
                            id="formatted-text-mask-input-internal-phone"
                            inputComponent={InternalPhoneMask}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Регионы"
                        value={this.state.regionCode}
                        onChange={this.onSetRegionCode}
                    >
                        {this.props.allRegions.map((option) => (
                            <option key={option.id} value={option.strCode} className="option">
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12}  sm={6}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Департамент"
                        value={this.state.departmentCode}
                        onChange={this.onSetDepartmentCode}
                    >
                        {this.props.allDepartments.map((option) => (
                            <option key={option.id} value={option.strCode} className="option">
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Должности"
                        value={this.state.jobPositionCode}
                        onChange={this.onSetJobPositionCode}
                    >
                        {this.props.allJobPositions.map((option) => (
                            <option key={option.id} value={option.strCode} className="option">
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Организации"
                        value={this.state.organisationCode}
                        onChange={this.onSetOrganisationCode}
                    >
                        {this.props.allOrganisations.map((option) => (
                            <option key={option.id} value={option.strCode} className="option">
                                {option.name}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        fullWidth
                        select
                        label="Руководитель сотрудника"
                        value={this.state.chiefId}
                        onChange={this.onSetChief}
                    >
                        {this.props.allChief.map((option) => (
                            <option key={option.id} value={option.id} className="option">
                                {option.fullName}
                            </option>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.isChief}
                                onChange={this.onSetIsChief}
                                name="isChief"
                                color="primary"
                            />
                        }
                        label="Сотрудник является руководителем"
                    />
                </Grid>
                <Grid item sm={6}>
                    <TextField
                        required
                        fullWidth
                        label="Комментарий"
                        multiline
                        rowsMax={4}
                        value={this.state.comment}
                        onChange={this.onSetComment}
                        variant="outlined"
                    />
                </Grid>
                <Grid item container justify="center">
                    <Button color="primary" variant="outlined" onClick={this.saveEmployee}>
                        <Typography>Сохранить</Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        editingEmployee: state.employee.editingEmployee,
        allDepartments: state.department.allDepartments,
        loadingDepartmentList: state.department.loadingDepartmentList,
        allRegions: state.region.allRegions,
        loadingRegionList: state.region.loadingRegionList,
        allOrganisations: state.organisation.allOrganisations,
        loadingOrganisationList: state.organisation.loadingOrganisationList,
        allJobPositions: state.jobPosition.allJobPositions,
        loadingJobPositionList: state.jobPosition.loadingJobPositionList,
        allEmailDomains: state.emailDomain.allEmailDomains,
        loadingEmailDomainList: state.emailDomain.loadingEmailDomainList,
        allChief: state.chief.allChief,
        loadingChiefList: state.chief.loadingChiefList
    };
};

const mapDispatchToProps = {
    fetchEmployeeList,
    fetchAllRegions,
    fetchAllDepartments,
    fetchAllOrganisations,
    fetchAllChief,
    fetchAllJobPositions,
    fetchAllEmailDomains,
    updateEmployee
}

export default connect(mapStateToProps, mapDispatchToProps)(EditEmployee);