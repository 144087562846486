import {
    GET_ALL_ROLE, LOADING_ROLE_LIST
} from "../types";

const initialState = {
    roleList: [],
    loadingRoleList: true
}

export const roleReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ALL_ROLE:
            return {
                ...state,
                roleList: action.payload.resources,
                loadingRoleList: false
            }
        case LOADING_ROLE_LIST:
            return {
                ...state,
                loadingRoleList: true
            }
        default:
            return state
    }
}