import {
    GET_ALL_ORGANISATIONS,
    GET_ORGANISATION_LIST,
    LOADING_ORGANISATION_LIST, START_ADD_ORGANISATION, START_REMOVE_ORGANISATION,
    START_UPDATE_ORGANISATION, STOP_ADD_ORGANISATION, STOP_REMOVE_ORGANISATION, STOP_UPDATE_ORGANISATION
} from "../types";

const initialState = {
    organisationList: [],
    pagination: [],
    allOrganisations: [],
    loadingOrganisationList: true,
    editingOrganisation: false,
    addingOrganisation: false,
    removingOrganisation: false
}

export const organisationReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_ORGANISATION_LIST:
            return {
                ...state,
                organisationList: action.payload.resources,
                pagination: action.payload.meta ? action.payload.meta.pagination : [],
                loadingOrganisationList: false
            }
        case GET_ALL_ORGANISATIONS:
            return {
                ...state,
                allOrganisations: action.payload.resources,
                loadingOrganisationList: false
            }
        case LOADING_ORGANISATION_LIST:
            return {
                ...state,
                loadingOrganisationList: true
            }
        case START_UPDATE_ORGANISATION:
            return {
                ...state,
                editingOrganisation: true
            }
        case STOP_UPDATE_ORGANISATION:
            return {
                ...state,
                editingOrganisation: false
            }
        case START_ADD_ORGANISATION:
            return {
                ...state,
                addingOrganisation: true
            }
        case STOP_ADD_ORGANISATION:
            return {
                ...state,
                addingOrganisation: false
            }
        case START_REMOVE_ORGANISATION:
            return {
                ...state,
                removingOrganisation: true
            }
        case STOP_REMOVE_ORGANISATION:
            return {
                ...state,
                removingOrganisation: false
            }
        default:
            return state
    }
}