import {
    GET_ALL_DEPARTMENTS,
    GET_DEPARTMENT_LIST,
    LOADING_DEPARTMENT_LIST,
    START_ADD_DEPARTMENT, START_REMOVE_DEPARTMENT, START_UPDATE_DEPARTMENT,
    STOP_ADD_DEPARTMENT, STOP_REMOVE_DEPARTMENT, STOP_UPDATE_DEPARTMENT
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchDepartmentList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_DEPARTMENT_LIST
            })

            let uri = `/departments?`;

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(param){
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_DEPARTMENT_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchAllDepartments = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_DEPARTMENT_LIST
            })

            const response = await httpWrapper('/departments', {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_DEPARTMENTS,
                payload: result
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const addDepartment = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_DEPARTMENT
            })
            const response = await httpWrapper('/departments', {
                method: 'post',
                credentials: "include",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_ADD_DEPARTMENT
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateDepartment = (data, strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_DEPARTMENT
            })
            const response = await httpWrapper(`/departments/${strCode}`, {
                method: 'put',
                credentials: "include",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_DEPARTMENT
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const removeDepartment = (strCode) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_REMOVE_DEPARTMENT
            })
            const response = await httpWrapper(`/departments/${strCode}`, {
                method: 'delete',
                credentials: "include",
                mode: "cors"
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_REMOVE_DEPARTMENT
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}