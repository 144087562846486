import React from "react";
import {
    AppBar,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Drawer,
    Toolbar,
    Typography
} from "@material-ui/core";
import {ChevronLeft, ExitToAppOutlined, KeyboardArrowLeft, Menu} from "@material-ui/icons";
import {Link} from "react-router-dom";
import AuthService from "../services/AuthService";
import Divider from "@material-ui/core/Divider";

export function AppNavBar() {
    const [show, setShow] = React.useState(false)

    const openDrawerToggle = () => {
        setShow(true)
    }

    const closeDrawerToggle = () => {
        setShow(false)
    }

    const logout = () => {
        localStorage.removeItem('username')
        localStorage.removeItem('roles')
        window.location.reload()
    }

    return (
        <AppBar position="fixed">
            <Toolbar className="flex row align-items-center space-between">
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={openDrawerToggle}
                >
                    <Menu/>
                </IconButton>
                <Typography component="h1" variant="h6" color="inherit" noWrap className="text-words">
                    Employee.l3s.ru
                </Typography>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="logout"
                    onClick={logout}
                >
                    <ExitToAppOutlined/>
                </IconButton>
            </Toolbar>
            <Drawer
                anchor="left"
                open={show}
                role="presentation"
                onClick={closeDrawerToggle}
            >
                <div className="flex align-items-center justify-content-end chevron-min-height">
                    <IconButton onClick={closeDrawerToggle}>
                        <ChevronLeft />
                    </IconButton>
                </div>
                <Divider />
                <List>
                    <Link to="/">
                        <ListItem button divider>
                            <ListItemText primary="Сотрудники"/>
                        </ListItem>
                    </Link>
                    {AuthService.checkPermission('ADMINISTRATOR_ROLE') &&
                    <div>
                        <Link to="/regions">
                            <ListItem button divider>
                                <ListItemText primary="Регионы"/>
                            </ListItem>
                        </Link>
                        <Link to="/organisations">
                            <ListItem button divider>
                                <ListItemText primary="Организации"/>
                            </ListItem>
                        </Link>
                        <Link to="/departments">
                            <ListItem button divider>
                                <ListItemText primary="Отделы"/>
                            </ListItem>
                        </Link>
                        <Link to="/job_positions">
                            <ListItem button divider>
                                <ListItemText primary="Должности"/>
                            </ListItem>
                        </Link>
                        <Link to="/corp_systems">
                            <ListItem button divider>
                                <ListItemText primary="Корпоративные системы"/>
                            </ListItem>
                        </Link>
                        <ListItem button divider>
                            <Link to="/email_domains">
                                <ListItemText primary="Почтовые домены"/>
                            </Link>
                        </ListItem>
                        <ListItem button divider>
                            <Link to="/users">
                                <ListItemText primary="Пользователи"/>
                            </Link>
                        </ListItem>
                    </div>
                    }
                    <ListItem button divider>
                        <Link to="/about">
                            <ListItemText primary="О приложении"/>
                        </Link>
                    </ListItem>
                </List>
            </Drawer>
        </AppBar>
    );
}