import React from 'react'
import {connect} from "react-redux";
import {Button, Typography, List, ListItem, ListItemText, IconButton} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {updateUserRole} from "../../redux/actions/userActions";
import {Add, Close} from "@material-ui/icons";
import {fetchAllRole} from "../../redux/actions/roleActions";
import {showAlert} from "../../redux/actions/alertActions";
import CircularProgress from "@material-ui/core/CircularProgress";


class UserRoleEdit extends React.Component {
    constructor(props) {
        super(props);

        let sendData = []
        for (let element of this.props.data.roles) {
            sendData.push(element.strCode)
        }

        this.state = {
            roleCode: '',
            roles: sendData ?? []
        }
    }

    componentDidMount() {
        this.props.fetchAllRole()
    }

    save = (strCode) => {
        this.props.updateUserRole({
            roles: this.state.roles
        }, strCode).then(() => {
            window.location.href = '/users'
        }).catch(e => {
            this.props.showAlert(e.message, true)
        })
    }

    onSetRoleCode = (event) => {
        this.setState({
            ...this.state,
            roleCode: event.target.value
        })
    }

    onAddRole = () => {
        let roles = this.state.roles
        roles.push(this.state.roleCode)
        this.setState({
            ...this.state,
            roles: roles
        })
    }

    onRemove = (index) => {
        let roles = this.state.roles
        roles.splice(index, 1)
        this.setState({
            ...this.state,
            roles: roles
        })
    }

    render() {
        let editing = this.props.editingUserRole

        if(editing){
            return (<Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (
            <Grid container spacing={3} className="padding-10-20">
                <Grid item xs={12} container direction="row" alignItems="flex-end" justify="space-between">
                    <Grid item xs={12} sm={8}>
                        <TextField
                            required
                            fullWidth
                            select
                            label="Роль"
                            value={this.state.roleCode}
                            onChange={this.onSetRoleCode}
                        >
                            {this.props.roleList.map((option, index) => (
                                <option key={index} value={option.strCode} className="option">
                                    {option.name}
                                </option>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Button color="primary" variant="outlined" onClick={this.onAddRole}>
                            <Add />
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} container direction="row" alignItems="center">
                    <List>
                        {
                            this.state.roles.map((role, index) => (
                                <ListItem key={index}>
                                    <Grid container direction="row" justify="space-between" alignItems="center">
                                        <Grid item xs={8}>
                                            <ListItemText primary={role}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <IconButton onClick={() => this.onRemove(index)}><Close/></IconButton>
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            ))
                        }
                    </List>
                </Grid>
                <Grid item xs={12} container justify="center" alignItems="center">
                    <Grid item>
                        <Button color="primary" variant="contained" onClick={() => this.save(this.props.data.strCode)}>
                            <Typography>Сохранить</Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    return {
        roleList: state.role.roleList,
        editingUserRole: state.user.editingUserRole,
    };
};

const mapDispatchToProps = {
    updateUserRole,
    fetchAllRole,
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(UserRoleEdit);