import {
    GET_ALL_EMAIL_DOMAINS,
    GET_EMAIL_DOMAIN_LIST,
    LOADING_EMAIL_DOMAIN_LIST, START_ADD_EMAIL_DOMAIN, START_REMOVE_EMAIL_DOMAIN,
    START_UPDATE_EMAIL_DOMAIN, STOP_ADD_EMAIL_DOMAIN, STOP_REMOVE_EMAIL_DOMAIN, STOP_UPDATE_EMAIL_DOMAIN
} from "../types";

const initialState = {
    emailDomainList: [],
    pagination: [],
    allEmailDomains: [],
    loadingEmailDomainList: true,
    editingEmailDomain: false,
    addingEmailDomain: false,
    removingEmailDomain: false
}

export const emailDomainReducer = (state = initialState, action) => {
    switch (action.type){
        case GET_EMAIL_DOMAIN_LIST:
            return {
                ...state,
                emailDomainList: action.payload.resources,
                pagination: action.payload.meta.pagination,
                loadingEmailDomainList: false
            }
        case GET_ALL_EMAIL_DOMAINS:
            return {
                ...state,
                allEmailDomains: action.payload.resources,
                loadingEmailDomainList: false
            }
        case LOADING_EMAIL_DOMAIN_LIST:
            return {
                ...state,
                loadingEmailDomainList: true
            }
        case START_UPDATE_EMAIL_DOMAIN:
            return {
                ...state,
                editingEmailDomain: true
            }
        case STOP_UPDATE_EMAIL_DOMAIN:
            return {
                ...state,
                editingEmailDomain: false
            }
        case START_ADD_EMAIL_DOMAIN:
            return {
                ...state,
                addingEmailDomain: true
            }
        case STOP_ADD_EMAIL_DOMAIN:
            return {
                ...state,
                addingEmailDomain: false
            }
        case START_REMOVE_EMAIL_DOMAIN:
            return {
                ...state,
                removingEmailDomain: true
            }
        case STOP_REMOVE_EMAIL_DOMAIN:
            return {
                ...state,
                removingEmailDomain: false
            }
        default:
            return state
    }
}