import {CLOSE_ALERT, SHOW_ALERT} from "../types";

export const showAlert = (message, error = false) => {
    return async dispatch => {
        dispatch({
            type: SHOW_ALERT,
            payload: {
                message: message,
                typeMessage: error
            }
        })
    }
}

export const closeAlert = () => {
    return async dispatch => {
        dispatch({
            type: CLOSE_ALERT
        })
    }
}