import React, {Component} from 'react';
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce';
import {SearchInput} from "./searchInput";

class DebouncedInputComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: '',
        };
    }

    componentDidMount() {
        this.sendTextChange = debounce(this.sendTextChange, 300);
        this.setState({text:this.props.text});
    }

    render() {
        return (
            <SearchInput onChange={this.handleTextChange} value={this.state.text} />
        );
    }

    handleTextChange = (e) => {
        this.setState({text: e.target.value});
        this.sendTextChange(e.target.value.trim())
    };

    sendTextChange = (text) => {
        this.props.updateText(text);
    };
}

DebouncedInputComponent.propTypes = {
    text: PropTypes.string.isRequired,
    updateText: PropTypes.func.isRequired,
};

export default DebouncedInputComponent;