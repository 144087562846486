import React from "react";
import PropTypes from "prop-types";
import {Button, Grid, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import {showAlert} from "../redux/actions/alertActions";
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

class FormAddEntity extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name
        }
    }

    onSetName = (event) => {
        this.setState({
            ...this.state,
            name: event.target.value
        })
    }

    onSendForm = async () => {
        this.props.sendForm({
            name: this.state.name
        }).then(() => {
            window.location.href = this.props.urlReload
        }).catch((e) => {
            this.props.showAlert(e.message, true)
        })
    }

    render() {
        const adding = this.props.adding

        if (adding) {
            return (<Grid container justify="center" alignItems="center">
                <Grid item>
                    <CircularProgress color="primary" />
                </Grid>
            </Grid>)
        }

        return (
            <Grid container spacing={3} className="padding-10-20">
                <Grid item xs={12}>
                    <TextField
                        required
                        label="Название"
                        fullWidth
                        value={this.state.name}
                        onChange={this.onSetName}
                    />
                </Grid>
                <Grid item container direction="row" justify="center">
                    <Button color="primary" variant="contained" onClick={this.onSendForm}>
                        <Typography>сохранить</Typography>
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

FormAddEntity.propTypes = {
    sendForm: PropTypes.func.isRequired,
    cancelForm: PropTypes.func.isRequired,
    urlReload: PropTypes.string.isRequired
};

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = {
    showAlert
}

export default connect(mapStateToProps, mapDispatchToProps)(FormAddEntity);