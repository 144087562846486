import React from 'react';
import './scss/app.scss'
import PropTypes from 'prop-types';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import {
    Container,
    CssBaseline,
    Toolbar,
    Zoom,
    Fab
} from "@material-ui/core";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import {ArrowUpward} from "@material-ui/icons";
import LoginPage from "./pages/LoginPage";
import AboutPage from "./pages/AboutPage";
import {AppNavBar} from "./components/AppNavBar";
import EmployeeList from "./pages/Employee/EmployeeList";
import Employee from "./pages/Employee/Employee";
import AddEmployee from "./pages/Employee/AddEmployee";
import NotFound from "./pages/404";
import AuthService from "./services/AuthService";
import CorpSystemList from "./pages/CorpSystem/CorpSystemList";
import RegionList from "./pages/Region/RegionList";
import OrganisationList from "./pages/Organisation/OrganisationList";
import JobPositionList from "./pages/JobPosition/JobPositionList";
import EmailDomainList from "./pages/EmailDomain/EmailDomainList";
import UserList from "./pages/User/UserList";
import DepartmentList from "./pages/Department/DepartmentList";
import AlertMessage from "./components/AlertMessage";


const PrivateRoute = ({component: Component, auth, permission, ...etc}) => {
    if (!permission) {
        if(!auth){
            return (<Redirect to="/login"/>)
        }
        return (
            <Route {...etc} render={props => <NotFound {...props} />}/>
        )
    }

    return (
        <Route
            {...etc}
            render={props =>
                auth ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login"/>
                )
            }
        />
    )
}

function ScrollTop(props) {
    const {children, window} = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className="scroll-top-button">
                {children}
            </div>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function App(props) {
    const isAuth = () => {
        const username = localStorage.getItem('username')
        return username ?? false
    }

    return (
        <Router>
            <CssBaseline/>
            {AuthService.currentUser()
            && <AppNavBar/>}
            <Toolbar id="back-to-top-anchor"/>
            <Container maxWidth="xl">
                <Switch>
                    <Route path="/login" component={LoginPage}/>
                    <PrivateRoute exact path="/about"
                                  component={AboutPage}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('USER_ROLE')}/>
                    <PrivateRoute exact path="/"
                                  component={EmployeeList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('USER_ROLE')}
                    />
                    <PrivateRoute exact path="/employees/add"
                                  component={AddEmployee}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/employees/:id"
                                  component={Employee}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('USER_ROLE')}/>
                    <PrivateRoute exact path="/corp_systems"
                                  component={CorpSystemList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/regions"
                                  component={RegionList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/organisations"
                                  component={OrganisationList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/job_positions"
                                  component={JobPositionList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/departments"
                                  component={DepartmentList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/email_domains"
                                  component={EmailDomainList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                    <PrivateRoute exact path="/users"
                                  component={UserList}
                                  auth={isAuth()}
                                  permission={AuthService.checkPermission('ADMINISTRATOR_ROLE')}/>
                </Switch>
            </Container>
            <AlertMessage/>
            <ScrollTop {...props}>
                <Fab color="secondary" size="small" aria-label="scroll back to top">
                    <ArrowUpward/>
                </Fab>
            </ScrollTop>
        </Router>
    );
}