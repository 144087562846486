import {
    GET_ALL_EMAIL_DOMAINS,
    GET_EMAIL_DOMAIN_LIST,
    LOADING_EMAIL_DOMAIN_LIST,
    START_ADD_EMAIL_DOMAIN,
    START_REMOVE_EMAIL_DOMAIN,
    START_UPDATE_EMAIL_DOMAIN,
    STOP_ADD_EMAIL_DOMAIN, STOP_REMOVE_EMAIL_DOMAIN,
    STOP_UPDATE_EMAIL_DOMAIN
} from "../types";
import {httpWrapper} from "../../libs/http";

export const fetchEmailDomainList = (page = null, perPage = null, param = null) => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_EMAIL_DOMAIN_LIST
            })

            let uri = `/email_domains?`;

            if(page && perPage){
                uri = uri + `page=${page}&limit=${perPage}`;
            }
            if(param){
                uri = param.param ? uri + `&${param.typeParam}=${param.param}` : uri;
            }

            const response = await httpWrapper(uri, {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_EMAIL_DOMAIN_LIST,
                payload: result
            })

        }catch (e){

            throw new Error(e.message)

        }
    }
}

export const fetchAllEmailDomains = () => {
    return async dispatch => {
        try {
            dispatch({
                type: LOADING_EMAIL_DOMAIN_LIST
            })
            const response = await httpWrapper('/email_domains', {credentials: "include", mode: "cors"});
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()

            dispatch({
                type: GET_ALL_EMAIL_DOMAINS,
                payload: result
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const addEmailDomain = (data) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_ADD_EMAIL_DOMAIN
            })
            const response = await httpWrapper('/email_domains', {
                method: 'post',
                credentials: "include",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_ADD_EMAIL_DOMAIN
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const updateEmailDomain = (data, id) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_UPDATE_EMAIL_DOMAIN
            })
            const response = await httpWrapper(`/email_domains/${id}`, {
                method: 'put',
                credentials: "include",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_UPDATE_EMAIL_DOMAIN
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}

export const removeEmailDomain = (id) => {
    return async dispatch => {
        try {
            dispatch({
                type: START_REMOVE_EMAIL_DOMAIN
            })
            const response = await httpWrapper(`/email_domains/${id}`, {
                method: 'delete',
                credentials: "include", mode: "cors"
            });
            if(!response.ok){
                let error = await response.json()
                throw new Error(error.message)
            }
            const result = await response.json()
            dispatch({
                type: STOP_REMOVE_EMAIL_DOMAIN
            })
        } catch (e) {
            throw new Error(e.message)
        }
    }
}